export const IconWASD = () => (
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="58"
      height="56"
      fill="none"
      viewBox="0 0 58 56"
    >
      <g filter="url(#filter0_d_2072_5582)">
        <rect
          width="18.231"
          height="18.231"
          x="19.885"
          y="6.769"
          stroke="#1A1023"
          rx="5.5"
          shapeRendering="crispEdges"
        ></rect>
        <g filter="url(#filter1_i_2072_5582)">
          <rect
            width="17.231"
            height="17.231"
            x="20.385"
            y="7.269"
            fill="#FF0041"
            rx="5"
          ></rect>
          <path
            fill="#fff"
            d="M33.858 11.406l-1.774 6.804h-2.006l-1.085-4.478-1.125 4.478h-2.006l-1.725-6.804h1.773l.98 4.952 1.21-4.952h1.823l1.163 4.952.989-4.952h1.783z"
          ></path>
          <path
            fill="#990027"
            fillRule="evenodd"
            d="M34.555 10.867l-2.054 7.881h-2.846l-.672-2.768-.695 2.768h-2.845l-1.998-7.88h2.908l.592 2.996.733-2.997h2.672l.694 2.954.59-2.954h2.921zm-3.469 5.491l-1.163-4.952h-1.822l-1.211 4.952-.98-4.952h-1.773l1.725 6.804h2.006l1.125-4.478 1.085 4.478h2.007l1.773-6.804h-1.783l-.989 4.952z"
            clipRule="evenodd"
          ></path>
        </g>
      </g>
      <g filter="url(#filter2_d_2072_5582)">
        <rect
          width="17.667"
          height="18.231"
          x="0.5"
          y="27"
          stroke="#1A1023"
          rx="5.5"
          shapeRendering="crispEdges"
        ></rect>
        <g filter="url(#filter3_i_2072_5582)">
          <rect
            width="16.667"
            height="17.231"
            x="1"
            y="27.5"
            fill="#FF0041"
            rx="5"
          ></rect>
          <path
            fill="#fff"
            d="M10.552 37.239H8.096l-.394 1.202H6.024l2.381-6.805h1.856l2.382 6.805h-1.697l-.394-1.202zm-.412-1.28l-.816-2.49-.806 2.49h1.622z"
          ></path>
          <path
            fill="#990027"
            fillRule="evenodd"
            d="M10.946 38.44l-.394-1.201H8.096l-.394 1.202H6.024l2.381-6.805h1.857l2.38 6.805h-1.696zm-2.477-.663l-.393 1.202H5.282l2.758-7.881h2.587l2.758 7.88h-2.813l-.393-1.2h-1.71zm.855-4.309l-.806 2.491h1.622l-.816-2.49zm.003 1.688l-.086.265h.173l-.087-.265z"
            clipRule="evenodd"
          ></path>
        </g>
      </g>
      <g filter="url(#filter4_d_2072_5582)">
        <rect
          width="17.667"
          height="18.231"
          x="20.166"
          y="27"
          stroke="#1A1023"
          rx="5.5"
          shapeRendering="crispEdges"
        ></rect>
        <g filter="url(#filter5_i_2072_5582)">
          <rect
            width="16.667"
            height="17.231"
            x="20.666"
            y="27.5"
            fill="#FF0041"
            rx="5"
          ></rect>
          <path
            fill="#fff"
            d="M29.092 38.523c-.481 0-.912-.08-1.294-.242a2.15 2.15 0 01-.918-.718c-.225-.316-.344-.698-.357-1.143h1.707c.025.252.11.445.253.581a.81.81 0 00.562.194c.238 0 .425-.055.563-.165a.59.59 0 00.206-.475.558.558 0 00-.169-.416c-.106-.11-.24-.2-.403-.272a5.967 5.967 0 00-.675-.242 7.24 7.24 0 01-1.04-.407 1.94 1.94 0 01-.694-.601c-.194-.265-.29-.61-.29-1.037 0-.633.221-1.128.665-1.483.444-.362 1.022-.543 1.734-.543.725 0 1.31.181 1.753.543.444.355.682.853.713 1.492h-1.735a.694.694 0 00-.234-.513.796.796 0 00-.553-.194.663.663 0 00-.469.165c-.119.103-.178.255-.178.455 0 .22.1.391.3.514.2.123.513.255.938.397.425.149.768.291 1.03.427.27.135.5.332.695.591.193.259.29.591.29.998a1.994 1.994 0 01-1.116 1.813c-.362.187-.79.28-1.284.28z"
          ></path>
          <path
            fill="#990027"
            fillRule="evenodd"
            d="M26.46 37.882c-.296-.416-.442-.907-.457-1.447l-.016-.554h1.708a5.533 5.533 0 01-.397-.18 2.464 2.464 0 01-.88-.76c-.28-.381-.397-.851-.397-1.361 0-.782.283-1.44.866-1.907.557-.454 1.258-.657 2.055-.657.808 0 1.515.202 2.074.657.576.462.874 1.112.912 1.89l.028.565h-1.718c.186.077.353.153.5.23a2.5 2.5 0 01.875.743c.28.374.4.832.4 1.328a2.536 2.536 0 01-1.403 2.294c-.45.232-.96.338-1.518.338-.538 0-1.037-.09-1.491-.282a2.673 2.673 0 01-1.137-.891l-.004-.006zm4.048-3.042a8.19 8.19 0 00-1.031-.427c-.425-.142-.738-.274-.938-.397a.574.574 0 01-.3-.514c0-.2.06-.352.178-.455a.663.663 0 01.469-.165c.225 0 .41.065.553.194a.694.694 0 01.235.514h1.734a2.43 2.43 0 00-.084-.539 1.821 1.821 0 00-.629-.954c-.443-.362-1.028-.543-1.753-.543-.712 0-1.29.181-1.734.543-.444.355-.666.85-.666 1.483 0 .426.097.772.29 1.037.195.265.426.465.695.6a7.25 7.25 0 001.04.408c.294.09.519.171.675.242.163.071.297.162.403.272.113.11.17.248.17.416a.59.59 0 01-.207.475c-.138.11-.325.165-.563.165a.81.81 0 01-.562-.194c-.144-.136-.228-.33-.253-.581h-1.707c.006.191.03.37.075.538.06.223.153.425.282.605.231.317.537.556.919.718.38.161.812.242 1.293.242.494 0 .922-.094 1.285-.281a1.994 1.994 0 001.115-1.813c0-.407-.097-.74-.29-.998a1.975 1.975 0 00-.694-.591zM28.83 36.6c.03.025.089.056.215.056.15 0 .214-.03.238-.047a.035.035 0 00.004-.004v-.001l.003-.011a.203.203 0 00.002-.058.032.032 0 00-.004-.005l-.006-.005-.005-.006a.732.732 0 00-.238-.156l-.003-.001-.004-.002a3.616 3.616 0 00-.296-.116l.012.12c.014.148.055.208.081.235zm.33-2.875l-.006-.104c-.006-.093-.03-.117-.047-.13l-.005-.004-.005-.005c-.032-.029-.088-.062-.211-.062-.092 0-.117.023-.121.028a.272.272 0 00-.004.07.178.178 0 00.044.035c.081.05.198.108.354.172z"
            clipRule="evenodd"
          ></path>
        </g>
      </g>
      <g filter="url(#filter6_d_2072_5582)">
        <rect
          width="17.667"
          height="18.231"
          x="39.834"
          y="27"
          stroke="#1A1023"
          rx="5.5"
          shapeRendering="crispEdges"
        ></rect>
        <g filter="url(#filter7_i_2072_5582)">
          <rect
            width="16.667"
            height="17.231"
            x="40.334"
            y="27.5"
            fill="#FF0041"
            rx="5"
          ></rect>
          <path
            fill="#fff"
            d="M48.696 31.637c.693 0 1.3.142 1.818.426.52.284.92.685 1.2 1.202.288.51.432 1.102.432 1.773 0 .666-.144 1.257-.432 1.774a2.94 2.94 0 01-1.209 1.202c-.519.284-1.122.426-1.81.426H46.23v-6.803h2.466zm-.103 5.369c.606 0 1.078-.171 1.415-.514.338-.342.506-.827.506-1.453 0-.627-.168-1.115-.506-1.464-.337-.349-.81-.523-1.416-.523h-.759v3.954h.76z"
          ></path>
          <path
            fill="#990027"
            fillRule="evenodd"
            d="M45.709 38.979v-7.881h2.986c.764 0 1.457.157 2.063.49l-.244.475c-.519-.284-1.125-.427-1.819-.427H46.23v6.805h2.465c.688 0 1.291-.143 1.81-.427a2.94 2.94 0 001.21-1.202c.287-.517.43-1.108.43-1.773 0-.672-.143-1.264-.43-1.774a2.956 2.956 0 00-1.2-1.202l.243-.476c.604.331 1.077.803 1.408 1.41.339.603.5 1.29.5 2.041 0 .748-.162 1.433-.499 2.04a3.469 3.469 0 01-1.42 1.412c-.605.332-1.294.489-2.052.489H45.71zm2.883-2.511c.51 0 .836-.142 1.051-.36.212-.215.35-.544.35-1.07 0-.524-.138-.86-.353-1.082-.216-.223-.54-.366-1.048-.366h-.238v2.878h.238zm0 .538c.607 0 1.078-.171 1.416-.514.337-.342.506-.827.506-1.453 0-.627-.169-1.115-.506-1.464-.338-.349-.81-.523-1.416-.523h-.76v3.954h.76z"
            clipRule="evenodd"
          ></path>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_2072_5582"
          width="19.231"
          height="20.231"
          x="19.385"
          y="6.269"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2072_5582"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_2072_5582"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_i_2072_5582"
          width="17.231"
          height="17.231"
          x="20.385"
          y="7.269"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="-3"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.601904 0 0 0 0 0 0 0 0 0 0.153427 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_2072_5582"></feBlend>
        </filter>
        <filter
          id="filter2_d_2072_5582"
          width="18.666"
          height="20.231"
          x="0"
          y="26.5"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2072_5582"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_2072_5582"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter3_i_2072_5582"
          width="16.666"
          height="17.231"
          x="1"
          y="27.5"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="-3"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.601904 0 0 0 0 0 0 0 0 0 0.153427 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_2072_5582"></feBlend>
        </filter>
        <filter
          id="filter4_d_2072_5582"
          width="18.666"
          height="20.231"
          x="19.666"
          y="26.5"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2072_5582"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_2072_5582"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter5_i_2072_5582"
          width="16.666"
          height="17.231"
          x="20.666"
          y="27.5"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="-3"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.601904 0 0 0 0 0 0 0 0 0 0.153427 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_2072_5582"></feBlend>
        </filter>
        <filter
          id="filter6_d_2072_5582"
          width="18.666"
          height="20.231"
          x="39.334"
          y="26.5"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2072_5582"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_2072_5582"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter7_i_2072_5582"
          width="16.666"
          height="17.231"
          x="40.334"
          y="27.5"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="-3"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.601904 0 0 0 0 0 0 0 0 0 0.153427 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_2072_5582"></feBlend>
        </filter>
      </defs>
    </svg>
);