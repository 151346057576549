import { FC } from 'react';
import classNames from 'classnames';

import styles from './_Container.module.scss';

import type { ComponentProps } from './_Container.types';


export const Container: FC<ComponentProps> = ({
  className,
  children
}) => {
  return <>
    <div className={ classNames(className, styles.component) }>
      { children }
    </div>
  </>
};
