import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';

import styles from './_Loader.module.scss';

import type { ComponentProps } from './_Loader.types';


export const Loader: FC<ComponentProps> = ({
  loaded,
  loading,
  animationDuration = 3000,
  animationLoadingPercent = 80,
  setLoading,
}) => {
  const [percent, setPercent] = useState(0)
  const [isCounting, setIsCounting] = useState(false)

  const rootClassName = classNames(styles.root, {
    [styles.loading]: loading
  })

  useEffect(() => {
    if (loading && loaded) {
      const loadedPercent = 100 - animationLoadingPercent
      setPercent((prevState) => prevState += loadedPercent)
    } else if (loading && !isCounting && (animationLoadingPercent > 0)) {
      setIsCounting(true)
    }
  }, [loading, loaded, isCounting, animationLoadingPercent]);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | undefined;
    if (isCounting) {
      let count = 0;
      interval = setInterval(() => {
        if (count < animationLoadingPercent) {
          count += 1
          setPercent((prevState) => prevState += 1)
        } else {
          clearInterval(interval)
          interval = undefined
        }
      }, animationDuration/animationLoadingPercent)
    } else if (!!interval) {
      clearInterval(interval)
      interval = undefined
    }
  }, [animationDuration, animationLoadingPercent, isCounting]);

  useEffect(() => {
    if (percent >= 100) {
      setLoading(false)
      setIsCounting(false)
      setPercent(0)
    }
  }, [percent, setLoading]);

  return (
    <div className={rootClassName}>
      <div className={styles.progress}>
        <span style={{width: `${percent}%`}}/>
      </div>
    </div>
  )
}
