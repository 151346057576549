import { atom } from 'jotai'
import { ScoreI } from 'types'

export const defaultScore = {
    runs: 0,
    score: 1, // Бэк требует значение > 0
    place: 0,
}

export const ScoreAtom = atom<ScoreI>(defaultScore)

// Сейчас стоят моковые данные