import { FC, useState } from 'react';


import type { ComponentProps } from './_Spawn.types';
import { Entity } from './Entity';


export const Spawn: FC<ComponentProps> = ({entities, width, height, handleLose}) => {
  const [destroyed, setDestroyed] = useState<boolean>(false);
  
  let totalProbability = entities.reduce((total, entity) => total + entity.spawnProbability, 0);
  
  if (totalProbability < 1) {
    totalProbability = 1
  }
  
  const random = Math.random() * totalProbability;
  let cumulativeProbability = 0;

  if (destroyed) {
    return null
  }

  for (const entity of entities) {
    cumulativeProbability += entity.spawnProbability;
    
    if (random < cumulativeProbability) {
        return <Entity entity={entity} stageWidth={width} stageHeight={height} handleLose={handleLose} setDestroyed={setDestroyed}/>
    }
  }

  return null
};