import { FC, useEffect, useRef, useState } from 'react';
import type { ComponentProps } from './_Entity.types';
import { Direction, SpawnDirection } from 'types';
import { useAtom } from 'jotai';
import { defaultPostionsAtom, directionAtom, entityTexturesAtom } from 'atoms';
import { Catcher } from './Catcher';
import { Squirrel } from './Squirrel';
import { Gull } from './Gull';
import { Mud } from './Mud';
import { Battery } from './Battery';
import { Runner } from './Runner';


function selectSpawnPoint(direction: string, spawnPoints: {[key: string]: SpawnDirection[]}, spawnFromCenter?: boolean): SpawnDirection {
  const possibleSpawnPoints =  spawnPoints[direction];
  const centerIndex = Math.floor(possibleSpawnPoints.length / 2);
  const maxDistance = Math.max(centerIndex, possibleSpawnPoints.length - centerIndex - 1);
  let probabilities;

  if (spawnFromCenter) {
    probabilities = Array.from({ length: possibleSpawnPoints.length }, (_, i) => maxDistance - Math.abs(centerIndex - i) + 1);
  } else {
    probabilities =  Array.from({ length: possibleSpawnPoints.length }, (_, i) => Math.abs(centerIndex - i) + 1);
  }

  const totalWeight = probabilities.reduce((acc, weight) => acc + weight, 0);

  let randomValue = Number((Math.random() * totalWeight).toFixed(1));
  for (let i = 0; i < possibleSpawnPoints.length; i++) {
      randomValue -= probabilities[i];
      if (randomValue <= 0) {
          return possibleSpawnPoints[i];
      }
  }

  // Fallback
  return possibleSpawnPoints[0];
}

export function getRandomDirection(): Exclude<Direction, null> {
  const directions: Exclude<Direction, null>[] = ['left', 'left-up', 'up', 'right-up', 'right', 'right-down', 'down', 'left-down'];
  const randomIndex = Math.floor(Math.random() * directions.length);
  return directions[randomIndex];
}

export const Entity: FC<ComponentProps> = ({entity, stageWidth, stageHeight, handleLose, setDestroyed}) => {
  const [ movementDirection,] = useAtom(directionAtom)
  const [ spawnDirection, setSpawnDirection ] = useState<SpawnDirection>(null)
  const [ mountDirection, setMountDirection ] = useState<Direction>(null)
  const lastMovementDirectionRef = useRef<Direction>(null);
  const [ entityTextures,] = useAtom(entityTexturesAtom);
  const [ defaultPostions, ] = useAtom(defaultPostionsAtom)

  const [spawnPosition, setSpawnPosition] = useState({x: 0, y: 0})

  if (movementDirection) {
    lastMovementDirectionRef.current = movementDirection;
  }

  useEffect(() => {
    if (!mountDirection) {
      setMountDirection(movementDirection || getRandomDirection())
    }
  }, [mountDirection, movementDirection])

  useEffect(() => {
    if (!spawnDirection) {
      if (entity.possibleSpawnPoints) {
        const selectedSpawnPoint = selectSpawnPoint(movementDirection || getRandomDirection(), entity.possibleSpawnPoints, entity.spawnFromCenter);
        setSpawnDirection(selectedSpawnPoint)
        setSpawnPosition(defaultPostions[selectedSpawnPoint ? selectedSpawnPoint : movementDirection || getRandomDirection()])
        return;
      }
      
      setSpawnDirection(movementDirection)
      setSpawnPosition(defaultPostions[movementDirection || getRandomDirection()])
    }
  }, [defaultPostions, entity.possibleSpawnPoints, entity.spawnFromCenter, movementDirection, spawnDirection])

  if (entity.name === 'catcher') {
    return <Catcher 
      texture={entityTextures[entity.name]} 
      tilePosition={spawnPosition} 
      stageWidth={stageWidth}
      handleLose={handleLose}
      stageHeight={stageHeight}
      setDestroyed={setDestroyed} />
  }

  if (entity.name === 'squirrel') {
    return <Squirrel 
      texture={entityTextures[entity.name]}
      tilePosition={spawnPosition}
      stageWidth={stageWidth} 
      direction={mountDirection}
      stageHeight={stageHeight}
      setDestroyed={setDestroyed}
    />
  }

  if (entity.name === 'gull') {
    return <Gull 
      texture={entityTextures[entity.name]}
      position={spawnPosition}
      stageWidth={stageWidth} 
      direction={mountDirection}
      stageHeight={stageHeight}
      setDestroyed={setDestroyed} 
    />
  }

  if (entity.name === 'mud') {
    return <Mud
      texture={entityTextures[entity.name]}
      position={spawnPosition}
      stageWidth={stageWidth}
      stageHeight={stageHeight}
      setDestroyed={setDestroyed} 
    />
  }

  if (entity.name === 'battery') {
    return <Battery
      texture={entityTextures[entity.name]}
      position={spawnPosition}
      stageWidth={stageWidth} 
    />
  }

  if (entity.name === 'runner') {
    return <Runner 
      texture={entityTextures[entity.name]}
      position={spawnPosition}
      stageWidth={stageWidth}
      handleLose={handleLose}
      stageHeight={stageHeight}
      setDestroyed={setDestroyed}
    />
  }

  return null;
};
