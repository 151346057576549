import {
    API_URL,
    Endpoint
} from 'const';

import type {
    UserResDataI,
    ResponseI,
    UserPostData,
    leaderBoard
} from 'types';
  
import { makeApiCall } from 'api/_makeApiCall';

export const getUserRank = (gameId: number, score: number): Promise<leaderBoard> => {
    return makeApiCall(`${API_URL}/${Endpoint.Rank}?game_id=${gameId}&score=${score}`)
        .then((response) => {
            if (response.data) {
                return response.data
            }
        })
}

export const getUserData = (phone: number, gameId: number ): Promise<UserResDataI> => {
    return makeApiCall(`${API_URL}/${Endpoint.User}?phone=${phone}&game_id=${gameId}`)
        .then((response) => {
            if(response.data) {
                return response.data
            }
        })
}

export const setUserData = (userPostData: UserPostData): Promise<ResponseI> => {
    const urlParams = new URLSearchParams(window.location.search);
    const promoLink = urlParams.get('promoLink');

    const requestData = promoLink ? { ...userPostData, promoLink } : userPostData

    return makeApiCall(`${API_URL}/${Endpoint.User}`, {
        method: 'post',
        body: JSON.stringify(requestData)
    })
}
