import {
  FC,
  memo,
  useState,
  useRef,
  useEffect,
} from 'react';
import { useAtom } from 'jotai';
import {
  Container,
  Sprite,
  useTick
} from '@pixi/react';

import type { ComponentProps } from './_Battery.types';

import { checkCollision } from 'helpers';

import { playerDataAtom } from 'atoms';


// дефолт тикера pixi
// зависит от нагрузки на устройство
const FPS = 60;

const BASE_WIDTH = 36;
const BASE_HEIGHT = 18;

const FADE_OUT_TIME = 3;


export const Battery: FC<ComponentProps> = memo(({
  texture,
  position,
  stageWidth
}) => {
  const width = BASE_WIDTH * stageWidth / 320;
  const height = BASE_HEIGHT * stageWidth / 320;

  const [ playerData, setPlayerData ] = useAtom(playerDataAtom);

  const [ fadeOutTime, setFadeOutTime ] = useState<number>(FADE_OUT_TIME);

  const isCollidedRef = useRef<boolean>(false);
  const componentRef = useRef<any>();

  const [currentPos, setCurrentPos] = useState({
    x: position.x - width/2,
    y: position.y - height/2
  })

  useEffect(() => {
    setCurrentPos(
      {
        x: position.x - width/2,
        y: position.y - height/2
      }
    )
  }, [height, position.x, position.y, width])


  useTick((tick) => {
    const time = tick / FPS;

    if (!componentRef.current || !playerData.ref) {
      return;
    }

    const collison = checkCollision(componentRef.current, playerData.ref);

    if (!isCollidedRef.current && collison) {
      isCollidedRef.current = true;

      if (!playerData.isTurbo) {
        setPlayerData((current) => ({
          ...current,
          isTurbo: true
        }));
      }
    }

    if (isCollidedRef.current) {
      setFadeOutTime((current) => current - time);
    }
  });


  return <>
    <Container 
      ref={ componentRef } 
      position={currentPos}
      width={ width }
      height={ height }
      alpha={ fadeOutTime / FADE_OUT_TIME }
      anchor={ 0.5 } 
    >
      <Sprite
        ref={ componentRef }
        position={[ width/2, height/2]}
        width={ width }
        height={ height }
        texture={ texture }
        anchor={ 0.5 }
      />
    </Container>
  </>
});
