import { FC, useContext } from 'react';
import { Background, Button, Container } from 'components';
import { IconLogo } from 'icons';

import styles from './_Menu.module.scss';

import type { ComponentProps } from './_Menu.types';
import { SetRouteContext } from 'context';


export const Menu: FC<ComponentProps> = () => {
  const setRoute = useContext(SetRouteContext);
  return <>
    <Background className={styles.component} color='white'>
      <Container> 
        <div className={styles.logo}>
          <IconLogo />
        </div>
        <div className={styles.buttons}>
          <Button onClick={() => setRoute('game')}>PLAY</Button>
          <Button modifiers={['yellow']} onClick={() => setRoute('guide')}>HOW TO PLAY</Button>
          <Button modifiers={['yellow']} onClick={() => setRoute('leaderboard')}>LEADERBOARD</Button>
          <Button modifiers={['yellow']} onClick={() => setRoute('otherGames')}>OTHER GAMES</Button>
        </div>
      </Container>
    </Background>
  </>
};
