import { FC, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';

import styles from './_LeaderBoard.module.scss';

import type { ComponentProps } from './_LeaderBoard.types';
import { LeaderBoardCard, ModalWrapper } from 'components';
import { PersonalScore, Prizes } from 'modals';
import { SetRouteContext } from 'context';
import { useAtom } from 'jotai';
import { BatteryleaderboardAtom, GameLeaderboardAtom } from 'atoms';
import { getBatteryLeaderboard, getGameLeaderboard } from 'api/leaderboard';
import { MAX_LEADERBOARD_SIZE } from 'const';
import { UserDataAtom } from 'atoms/_userAtom';

export const LeaderBoard: FC<ComponentProps> = ({
  className,
}) => {
  const [isPrizesModalOpen, setPrizesModalOpen] = useState(false)
  const [isScoreModalOpen, setScoreModalOpen] = useState(false)
  const setRoute = useContext(SetRouteContext);
  const [batteryleaderboard, setBatteryleaderboard] = useAtom(BatteryleaderboardAtom)
  const [gameLeaderboard, setGameLeaderboard] = useAtom(GameLeaderboardAtom)
  const [userData,] = useAtom(UserDataAtom)
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() =>{
    if (activeTab === 0) {
      getGameLeaderboard(MAX_LEADERBOARD_SIZE).then((response) => {
        if (response) {
          setGameLeaderboard(response.data)
        }
      })
    } else {
      getBatteryLeaderboard(MAX_LEADERBOARD_SIZE).then((response) => {
        if (response) {
          setBatteryleaderboard(response.data)
        }
      })
    }


  }, [activeTab, setBatteryleaderboard, setGameLeaderboard, userData])

  const tabs = [
    {
      title: 'Lucky Run',
      content: gameLeaderboard
    },
    {
      title: 'Batery Total',
      content: batteryleaderboard
    }
  ]

  return <>
      <ModalWrapper className={classNames(styles.component, className)} title={'leaderboard'} button={{
          children: 'back',
          onClick: ()=>{setRoute('menu')}
      }} hasGift={true} onGiftClick={() => setPrizesModalOpen(true)}>
        <LeaderBoardCard 
          setModalOpen={()=>setScoreModalOpen(true)} 
          tabs={tabs} 
          setActiveTab={setActiveTab} 
          activeTab={activeTab}/>
      </ModalWrapper>

      {isPrizesModalOpen && <Prizes onClick={() => setPrizesModalOpen(false)} />}
      {isScoreModalOpen && <PersonalScore data={userData} onClick={()=> setScoreModalOpen(false)}/>}
  </>
};
