import { FC, useEffect } from 'react';

import type { ComponentProps } from './_EntitiesContainer.types';
import { Container } from '@pixi/react';
import { useAtom } from 'jotai';
import { defaultPostionsAtom, fieldCenterAtom } from 'atoms';


export const EntitiesContainer: FC<ComponentProps> = ({
  tilePosition,
  width,
  height,
  children
}) => {
  const [ , setDefaultPostions ] = useAtom(defaultPostionsAtom)
  const [, setCenter] = useAtom(fieldCenterAtom)

  const xSpawnRange = width * 2.5;
  const ySpawnRange = height * 2.5;

  useEffect(() => {
    const xCurrentCenter = -tilePosition.x + width / 2
    const yCurrentCenter = -tilePosition.y + height / 2

    setCenter(()=> ({
      x:  xCurrentCenter,
      y: yCurrentCenter
    }))

    setDefaultPostions((prevState) => ({
      ...prevState,
      'up1': {
        x: xCurrentCenter - xSpawnRange + width * 0.75,
        y: yCurrentCenter - ySpawnRange
      },
      'up': {
        x: xCurrentCenter,
        y: yCurrentCenter - ySpawnRange
      },
      'up2': {
        x: xCurrentCenter + xSpawnRange - width * 0.75,
        y: yCurrentCenter
      },


      'down1': {
        x: xCurrentCenter + xSpawnRange + width * 0.75,
        y: yCurrentCenter + ySpawnRange
      },
      'down': {
        x: xCurrentCenter,
        y: yCurrentCenter + ySpawnRange
      },
      'down2': {
        x: xCurrentCenter - xSpawnRange + width * 0.75,
        y: yCurrentCenter + ySpawnRange
      },


      'right-up': {
        x: xCurrentCenter + xSpawnRange,
        y: yCurrentCenter - ySpawnRange + height * 1.25
      },
      'right1': {
        x: xCurrentCenter + xSpawnRange ,
        y: yCurrentCenter + ySpawnRange - height / 1.5
      },
      'right': {
        x: xCurrentCenter + xSpawnRange,
        y: yCurrentCenter
      },
      'right2': {
        x: xCurrentCenter + xSpawnRange ,
        y: yCurrentCenter + ySpawnRange + height / 1.5
      },
      'right-down': {
        x: xCurrentCenter + xSpawnRange,
        y: yCurrentCenter + ySpawnRange - height * 1.25,
      },


      'left-up': {
        x: xCurrentCenter - xSpawnRange,
        y: yCurrentCenter - ySpawnRange + height * 1.25
      },
      'left1': {
        x: xCurrentCenter - xSpawnRange,
        y: yCurrentCenter + ySpawnRange + height / 1.5,
      },
      'left': {
        x: xCurrentCenter - xSpawnRange,
        y: yCurrentCenter
      },
      'left2': {
        x: xCurrentCenter - xSpawnRange,
        y: yCurrentCenter + ySpawnRange - height / 1.5,
      },
      'left-down': {
        x: xCurrentCenter - xSpawnRange,
        y: yCurrentCenter + ySpawnRange - height * 1.25,
      },
    }))
  }, [height, setCenter, setDefaultPostions, tilePosition.x, tilePosition.y, width, xSpawnRange, ySpawnRange])
  return <>
      <Container  
        width={ width }
        height={ height }
        position={ tilePosition }
        scale={{ x: 1, y: 1 }}
      >
        {children}
      </Container>
  </>
};
