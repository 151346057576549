type ApiParameters = {
    method?: 'get' | 'post';
    body?: BodyInit | null | undefined;
};

export const makeApiCall = (
    endpoint: string,
    parameters?: ApiParameters
  ) => {
    const {
      method = 'get',
      body
    } = parameters || {};
  
    return fetch(endpoint, {
      method,
      body
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.error(error);
      });
  };