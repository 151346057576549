import { EntityI, SpawnDirection } from "types";

const runnerPossibleSpawnPoints: {[key: string]: SpawnDirection[]} = {
    'up': ['left', 'left-up', 'up1', 'up', 'up2' ,'right-up', 'right'],
    'down': ['left', 'left-down', 'down1', 'down', 'down2', 'right-down', 'right'],
    'left': ['up', 'left-up', 'left1', 'left', 'left2', 'left-down', 'down'],
    'right': ['up','right-up', 'right1', 'right', 'right2','right-down', 'down'],
    'left-up': ['left1', 'left', 'left2', 'left-up', 'up1', 'up', 'up2'],
    'left-down': ['down1',  'down', 'down2','left-down', 'left1', 'left', 'left2'],
    'right-up': ['right2', 'right', 'right1', 'right-up', 'up2', 'up', 'up1' ],
    'right-down': ['down2', 'down', 'down1', 'right-down', 'right2', 'right', 'right1'], 
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const batteryPossibleSpawnPoints: {[key: string]: SpawnDirection[]} = {
    'up': ['left2', 'left-up', 'up1', 'up', 'up2', 'right-up', 'right1'],
    'down': ['right2', "right-down", "down1", "down", "down2", "left-down", "left1"],
    'left': ['down2', 'left-down', 'left1', "left", "left2", "left-up", "up1"],
    'right': ["up2", "right-up", "right1", "right", "right2", 'right-down'],
    'left-up': ["left1", "left", "left2", "left-up", "up1", 'up', "up2"],
    'left-down': ["down1", "down", "down2", 'left-down', 'left1', "left", "left2"],
    'right-up': ["up1", 'up', "up2", "right-up", "right1", "right", "right2"],
    'right-down': ["right1", "right", "right2", "right-down", "down1", "down", "down2"]
}

export const DEFAUL_DISTANCE_MULTIPLIER = 3
export const RUNNER_DISTANCE_MULTIPLIER = 4

export const MAIN_ENTITIES: EntityI[] = [
    {
        name: 'runner',
        spawnProbability: 0.5,
        textureUrl: './sprites/runner/texture.json',
        possibleSpawnPoints: runnerPossibleSpawnPoints,
        spawnFromCenter: true
    },
    {
        name: 'catcher',
        spawnProbability: 0.2,
        textureUrl: './sprites/catcher/texture.json'
    },
    {
        name: 'gull',
        spawnProbability: 0.1,
        textureUrl: './sprites/gull/texture.json'
    },
    {
        name: 'squirrel',
        spawnProbability: 0.1,
        textureUrl: './sprites/squirrel/texture.json'
    },
    {
        name: 'mud',
        spawnProbability: 0.1,
        textureUrl: './sprites/mud/texture.json'
    }
]

export const BATTERY_ENTITIE: EntityI = {
    name: 'battery',
    spawnProbability: 0.3,
    textureUrl: './sprites/battery.png',
    possibleSpawnPoints: batteryPossibleSpawnPoints,
}

export const BALL = [
    {
        name: 'ball_down',
        textureUrl: './sprites/ball/down/texture.json',
    }, {
        name: 'ball',
        textureUrl: './sprites/ball/idle/texture.json',
    }, {
        name: 'ball_left',
        textureUrl: './sprites/ball/left/texture.json',
    }, {
        name: 'ball_left-down',
        textureUrl: './sprites/ball/left-down/texture.json',
    }, {
        name: 'ball_left-up',
        textureUrl: './sprites/ball/left-up/texture.json',
    }, {
        name: 'ball_right',
        textureUrl: './sprites/ball/right/texture.json',
    }, {
        name: 'ball_right-down',
        textureUrl: './sprites/ball/right-down/texture.json',
    }, {
        name: 'ball_right-up',
        textureUrl: './sprites/ball/right-up/texture.json',
    }, {
        name: 'ball_up',
        textureUrl: './sprites/ball/up/texture.json',
    }
]
