export const SERVER_URL = 'https://luckyrun.igm.gg';
export const GAME_ID = 1;
export const MAX_LEADERBOARD_SIZE = 10;

export const Endpoint = {
    Leaderboard: {
        Battery: 'game/leaderboard',
        Game: `game/leaderboard/${GAME_ID}`
    },

    User: 'game/user',
    Rank: 'game/rank',
    Games: 'game/over-games'
}
