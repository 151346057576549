import { FC, useEffect, useRef } from 'react';
import classNames from 'classnames';

import styles from './_LottieCounter.module.scss';

import type { ComponentProps } from './_LottieCounter.types';
import Lottie from "lottie-react";


export const LottieCounter: FC<ComponentProps> = ({
  className,
  lottieData,
  isPlaying
}) => {
  const lottie = useRef<any>()

  useEffect(() => {
    if (!lottieData) return

    if (isPlaying) {
      lottie.current.play()
    } else {
      lottie.current.pause()
    }
  }, [isPlaying, lottieData])

  return <>
    <div className={ classNames(className, styles.component) }>
      {lottieData && (<Lottie lottieRef={lottie} rendererSettings={{preserveAspectRatio: 'xMidYMid slice'}} animationData={lottieData} autoplay={false}/>)}
    </div>
  </>
};
