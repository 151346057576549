import {
  FC,
  useState,
  useEffect,
  useRef
} from 'react';
import { useAtom } from 'jotai';

import type { Direction } from 'types';
import type { ComponentProps } from './_Catcher.types';

import { fieldCenterAtom, playerDataAtom } from 'atoms';
import { checkCollision } from 'helpers';

import { CatcherSprite } from './CatcherSprite';
import { DEFAUL_DISTANCE_MULTIPLIER } from 'const';

type ObjectParams = {
  x: number;
  y: number;
  width: number;
  height: number;
}

function getCatcherDirection(obj1: ObjectParams, obj2: ObjectParams): Direction {
  const {x: x1, y: y1, width: width1, height: height1} = obj1;
  const {x: x2, y: y2, width: width2, height: height2} = obj2;

  const left1 = x1 - width1 / 2;
  const right1 = x1 + width1 / 2;
  const top1 = y1 - height1 / 2;
  const bottom1 = y1 + height1 / 2;

  const left2 = x2 - width2 / 2;
  const right2 = x2 + width2 / 2;
  const top2 = y2 - height2 / 2;
  const bottom2 = y2 + height2 / 2;

  if (right2 < left1) {
      return (bottom2 < top1) ? 'left-up' :
             (top2 > bottom1) ? 'left-down' : 'left';
  }

  if (left2 > right1) {
      return (bottom2 < top1) ? 'right-up' :
             (top2 > bottom1) ? 'right-down' : 'right';
  }

  if (top2 >= top1 ) return 'down';
  if (bottom2 <= top1) return 'up';

  return 'up'; // Если ни одно из условий не выполнено, по умолчанию смотрим вверх
}

const BASE_SIZE = 70;


export const Catcher: FC<ComponentProps> = ({texture, tilePosition, stageWidth, stageHeight, handleLose, setDestroyed}) => {
  const [ playerData, ] = useAtom(playerDataAtom);
  const [ isCollided, setCollided ] = useState<boolean>(false);
  const componentRef = useRef<any>();
  const [ direction, setDirection ] = useState<Direction>('up')
  const [center,] = useAtom(fieldCenterAtom)
  const size = BASE_SIZE * stageWidth / 320;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (playerData.isTurbo) return
    const collison = checkCollision(componentRef.current, playerData.ref)
    
    if (!isCollided && collison && !playerData.ref.isTurbo) {
      setCollided(collison);
      handleLose()
    }
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!texture || !componentRef.current || !playerData.ref) return
    setDirection(getCatcherDirection(playerData.ref.getBounds(true), componentRef.current.getBounds()))
  })

  useEffect(() => {
    const {x: playerX, y: playerY} = center
    const {x:  componentX, y: componentY} = tilePosition
    if (componentX===0 || componentY===0) return
    const xDistance = stageWidth * DEFAUL_DISTANCE_MULTIPLIER 
    const yDistance = stageHeight * DEFAUL_DISTANCE_MULTIPLIER 
    const deltaX = Math.abs(playerX - componentX)
    const deltaY = Math.abs(playerY - componentY)

    if (deltaX >= xDistance || deltaY >= yDistance) {
      setDestroyed(true)
    }
  }, [center, center.x, center.y, setDestroyed, stageHeight, stageWidth, tilePosition])

  return <>
    <CatcherSprite
      key={ direction ? direction : 'up' }
      textures={direction && texture.animations[direction] }
      tilePosition={ tilePosition }
      size={ size }
      componentRef={componentRef}
    />
  </>
};
