import { FC } from 'react';
import classNames from 'classnames';

import styles from './_GameCard.module.scss';

import type { ComponentProps } from './_GameCard.types';
import { IconGameSceleton } from 'icons';
import { Button } from 'components';


export const GameCard: FC<ComponentProps> = ({
  className,
  game,
}) => {
  return <>
    <div className={ classNames(className, styles.component) }>
      <div className={styles.top}>
        {game.isSoon ? (
          <IconGameSceleton/>) :
          <img src={game.img}  width={100} height={91} alt={game.description} />
        }
      </div>
      <Button href={game.url} modifiers={['straight']} className={styles.button} isDisabled={game.isSoon} isExternal={true}>{!game.isSoon ? 'Play' : 'Soon'}</Button>
    </div>
  </>
};
