/* eslint-disable react-hooks/exhaustive-deps */
import {
  FC,
  useState,
  useRef,
  useEffect
} from 'react';
import { Assets } from 'pixi.js';
import { Stage as PixiStage} from '@pixi/react';
import {
  Provider as StoreProvider,
  createStore,
  useAtom
} from 'jotai';

import styles from './_Game.module.scss';

import {
  MAIN_ENTITIES,
  BATTERY_ENTITIE,
  BALL
} from 'const';

import { loadLottie } from 'helpers';

import {
  defaultPostionsAtom,
  directionAtom,
  entityTexturesAtom,
  fieldCenterAtom,
  movementDistanceAtom,
  playerDataAtom
} from 'atoms';
import { ScoreAtom } from 'atoms/_scoreAtom';

import {
  useIsTouchDevice,
  useWindowResize,
  useDirection
} from 'hooks';

import {
  Catched,
  LoaderScreen
} from 'modals';

import { Runs } from './Runs';
import { LottieCounter } from './LottieCounter';
import { Stage } from './Stage';
import { Joystick } from './Joystick';
import { ControlHint } from './ControlHint';
import { Pretimer } from './Pretimer';


export const pixiStore = createStore();
pixiStore.set(movementDistanceAtom, 0)
pixiStore.set(playerDataAtom, {
  x: 0,
  y:0,
  width: 0,
  height: 0,
  isTurbo: false,
  ref: null
})
pixiStore.set(defaultPostionsAtom, {})
pixiStore.set(fieldCenterAtom, {x: 0, y: 0})

export const Game: FC = () => {
  const [ stageSize, setStageSize ] = useState({ width: 0, height: 0 });
  const [ movementDirection, setMovementDirection ] = useAtom(directionAtom);
  pixiStore.set(directionAtom, movementDirection);
  const [ isControlHintHidden, setControlHintHidden ] = useState(false);
  const [entityTextures, setEntityTextures] = useAtom(entityTexturesAtom)
  pixiStore.set(entityTexturesAtom, entityTextures)

  const loadPromisesRef = useRef<{[key:string]: Promise<any>}>({});
  const componentRef = useRef<HTMLDivElement | null>(null);
  
  const isTouch = useIsTouchDevice();

  const [loading, setLoading] = useState(true);
	const [loaded, setLoaded] = useState(false);
  const [runnerLottieData, setRunnerLottieData] = useState<Response>()
  const [pretimerLottieData, setPretimerLottieData] = useState<Response>()
  const [isPretimerActive, setPretimerActive] = useState(false)

  const [score, setScore] = useAtom(ScoreAtom)
  const [catched, setCatched] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)


  const handleLose = () => {
    setIsPlaying(false);
    setCatched(true);
  };

  const handleWindowResize = () => {
    setStageSize({
      width: componentRef.current?.clientWidth || 0,
      height: componentRef.current?.clientHeight || 0
    });
  };

  const controlHandler = () => {
    if (isControlHintHidden || loading) return
    setControlHintHidden(true)
    setPretimerActive(true)
    window.removeEventListener('keydown', controlHandler)
  }


  useWindowResize(handleWindowResize);
  useDirection((direction) => setMovementDirection(direction));

  useEffect(() => {
    window.addEventListener('keydown', controlHandler)

    return () => {
      window.removeEventListener('keydown', controlHandler)
    }
  }, [loading]);

  useEffect(() => {
    [...MAIN_ENTITIES, BATTERY_ENTITIE, ...BALL].forEach((item) => {
      if (
        item.textureUrl &&
        !loadPromisesRef.current[item.name] &&
        !Assets.cache.has(item.name)
      ) {
        Assets.add({ alias: item.name, src: item.textureUrl });

        loadPromisesRef.current[item.name] = Assets.load(item.name)
          .then((data) => setEntityTextures((prevState) => ({
            ...prevState,
            ...{[item.name]: data}
          })));
      }
    });

    const runnersLottiePromise = loadLottie('./lottie/runners.json')
      .then((response) => setRunnerLottieData(response));

    const pretimerLottiePromise = loadLottie('./lottie/pretimer.json')
      .then((response) =>  setPretimerLottieData(response))

    Promise.all([...Object.values(loadPromisesRef.current), runnersLottiePromise, pretimerLottiePromise])
      .then(() => setLoaded(true));
  }, []);
  

  return <>
    <div
      ref={ componentRef }
      className={ styles.component }
      onTouchStart={ controlHandler }
    >
      {
        loading ? <>
          <LoaderScreen  
            titleText={'loading'} 
            loaded={loaded} 
            loading={loading} 
            setLoading={setLoading} 
            animationDuration={3000}
            hasVideo={true} 
            className={styles.loader}
            wrapperClassName={styles.loaderWrapper}
          />
        </> : <>
          <Runs runs={score.runs}/>
          <LottieCounter lottieData={runnerLottieData} isPlaying={isPlaying}/>
          {isPretimerActive && <Pretimer 
            lottieData={pretimerLottieData} 
            isActive={isControlHintHidden}
            setPretimerActive={setPretimerActive}
            setIsPlaying={setIsPlaying}
          />}
    
          <PixiStage { ...stageSize }>
            <StoreProvider store={ pixiStore }>
              <Stage
                size={ stageSize }
                movementDirection={ movementDirection }
                isPlaying={ isPlaying }
                setScore={ setScore }
                onLose={ handleLose }
              />
            </StoreProvider>
          </PixiStage>
    
          {
            loaded && <>
              <Joystick
                parentFrameRef={ componentRef }
                onMove={ (direction) => setMovementDirection(direction) }
              />
            </>
          }
    
          { !isControlHintHidden && <ControlHint isTouch={ isTouch }/> }
        </>
      }
    </div>
    {catched && <Catched />}
  </>
};
