export const IconLogo = () => (
  <svg width="280" height="158" viewBox="0 0 280 158" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_114_13872)">
      <g clipPath="url(#clip1_114_13872)">
        <path d="M255.5 22.6275L249.386 17.1644L221.653 18.7705L212.783 19.2846L210.82 19.3979L195.496 20.2837L184.854 20.8994L181.374 21.0998L175.714 21.428L151.265 22.8424L151.835 32.7608C150.446 31.178 148.865 29.7606 147.09 28.5234C141.896 24.9074 135.672 23.281 128.59 23.6905C123.038 24.0129 118.078 25.5667 113.851 28.3172C112.861 28.9619 111.926 29.6619 111.046 30.4112L105.54 25.4883L81.0905 26.9027L78.4502 27.0567L54.0677 28.4682L48.952 28.7644L24.5 30.1789L27.8409 88.2806L33.9554 93.7466L72.8047 91.4986L72.5413 86.93C73.6327 87.6648 74.7966 88.3096 76.0299 88.8556C79.9093 90.5692 84.2462 91.3011 88.9218 91.031C93.5626 90.7638 97.8242 89.5498 101.588 87.4209C105.569 85.1729 108.675 81.9462 110.82 77.8336C111.084 77.3282 111.33 76.8083 111.556 76.2827C112.372 76.9623 113.232 77.5925 114.132 78.1763C116.521 81.1039 119.462 83.4448 122.919 85.1613C127.432 87.4034 132.539 88.3764 138.092 88.0569C144.893 87.6619 150.689 85.4924 155.341 81.6063L160.72 86.416L185.169 85.0015L187.844 81.8939L190.948 84.6675L214.32 83.3141L238.836 81.8968L237.733 62.728L255.5 22.6275Z" fill="#FF0041"/>
        <path d="M45.6053 71.5079L43.5006 34.8984L30.6145 35.6449L33.2896 82.1465L60.5759 80.5695L60.0056 70.6743L45.6053 71.5079Z" fill="white"/>
        <path d="M100.091 31.6223L87.205 32.3687L88.7567 59.3473C88.8986 61.8102 88.4064 63.7678 87.2802 65.2228C86.154 66.6779 84.4083 67.4737 82.0401 67.6102C79.6719 67.7467 77.8683 67.1688 76.6292 65.8705C75.3901 64.5752 74.6981 62.6728 74.5534 60.1664L73.0016 33.1878L60.1792 33.9284L61.731 60.907C61.98 65.217 63.0309 68.8184 64.8924 71.7112C66.7511 74.6039 69.2061 76.7241 72.2518 78.0688C75.2974 79.4135 78.7049 79.9799 82.4744 79.7592C86.2438 79.5413 89.6311 78.5829 92.6333 76.8867C95.6355 75.1906 97.9487 72.7858 99.5728 69.6694C101.197 66.553 101.886 62.8616 101.64 58.598L100.091 31.6223Z" fill="white"/>
        <path d="M136.268 62.937C134.905 64.0145 133.263 64.6564 131.341 64.8655C128.371 65.185 125.889 64.3805 123.895 62.4462C121.9 60.5119 120.707 57.7498 120.322 54.1601C119.943 50.6138 120.522 47.6833 122.062 45.3657C123.602 43.0509 125.858 41.7323 128.825 41.4128C130.745 41.2066 132.487 41.4825 134.048 42.2464C135.608 43.0073 136.897 44.2213 137.916 45.8826L151.734 44.3927C150.032 39.2171 147.05 35.2962 142.788 32.6329C138.529 29.9696 133.518 28.9473 127.754 29.5688C123.211 30.0597 119.303 31.4886 116.026 33.8586C112.746 36.2285 110.349 39.3014 108.834 43.0741C107.317 46.8498 106.805 51.0117 107.291 55.5687C107.783 60.1663 109.173 64.1481 111.454 67.5143C113.736 70.8804 116.729 73.3695 120.435 74.9843C124.141 76.602 128.263 77.1626 132.806 76.6746C138.567 76.0531 143.248 73.9852 146.847 70.4738C150.446 66.9625 152.527 62.4956 153.095 57.0731L139.276 58.5631C138.637 60.4015 137.632 61.8595 136.268 62.937Z" fill="white"/>
        <path d="M240.237 23.5133L225.704 24.3527L218.585 42.7663L209.399 25.2966L198.021 25.9559L184.666 26.7284L171.418 47.6137L170.265 27.562L157.379 28.3084L160.052 74.8101L172.941 74.0666L171.768 53.6838L187.471 73.2243L203.055 72.3211L183.794 49.4144L196.857 29.8622L212.8 56.9338L213.651 71.7082L226.604 70.9589L225.753 56.1844L240.237 23.5133Z" fill="white"/>
        <path d="M215.553 82.1756L209.766 81.9578L209.983 76.152L185.511 75.2255L185.149 84.8273L182.816 80.9355L180.815 80.86L177.248 74.9118L158.181 74.1915L133.709 73.265L131.066 73.1663L106.66 72.2427L106.316 81.3769C104.567 78.551 102.094 76.2449 98.9475 74.5139C95.6211 72.6871 91.5969 71.6735 86.9792 71.4992L60.8567 70.5117L58.6709 128.669L64.2382 134.692L83.8293 135.433L88.7105 135.619L90.0654 135.671L117.965 136.725L110.603 122.761C112.786 125.866 115.467 128.732 118.582 131.399C119.85 132.566 121.254 133.589 122.797 134.454C126.494 136.531 130.744 137.672 135.423 137.849C140.067 138.024 144.427 137.222 148.373 135.465C150.202 134.652 151.884 133.632 153.407 132.421L158.815 138.27L183.288 139.197L193.261 139.574L213.365 140.335L215.553 82.1756Z" fill="#FF0041"/>
        <path d="M96.1682 79.6111C93.6263 78.2141 90.4938 77.4474 86.7649 77.3051L66.4269 76.5354L64.6782 123.084L77.5788 123.571L78.2244 106.404L79.6054 106.456L88.1748 123.972L102.59 124.518L92.807 105.965C96.1798 105.079 98.7767 103.47 100.601 101.135C102.425 98.8031 103.394 96.0498 103.513 92.8811C103.62 90.0639 103.056 87.4994 101.825 85.1817C100.595 82.864 98.7101 81.0081 96.1682 79.6111ZM88.9363 96.6946C88.0272 97.4991 86.6955 97.865 84.941 97.8011L78.5573 97.56L78.9221 87.8537L85.3058 88.0947C87.0168 88.1586 88.3051 88.6379 89.1737 89.5295C90.0393 90.4211 90.4475 91.6177 90.3896 93.1135C90.3288 94.6993 89.8453 95.893 88.9363 96.6946ZM139.279 79.2916L152.18 79.7796L151.166 106.784C151.007 111.054 149.971 114.664 148.057 117.609C146.143 120.557 143.613 122.729 140.463 124.132C137.313 125.535 133.851 126.162 130.079 126.02C126.303 125.878 122.965 124.992 120.061 123.359C117.158 121.73 114.914 119.386 113.339 116.328C111.761 113.273 111.055 109.587 111.217 105.271L112.23 78.2664L125.064 78.7514L124.051 105.756C123.955 108.266 124.465 110.226 125.577 111.632C126.688 113.04 128.428 113.79 130.797 113.88C133.165 113.97 134.98 113.342 136.239 112.003C137.499 110.662 138.173 108.759 138.266 106.293L139.279 79.2916ZM203.982 81.7371L202.233 128.285L189.333 127.797L173.132 101.135L172.153 127.15L159.252 126.662L161.001 80.1136L173.902 80.6015L190.091 107.595L191.081 81.2492L203.982 81.7371Z" fill="white"/>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_114_13872">
        <rect width="280" height="157.5" fill="white"/>
      </clipPath>
      <clipPath id="clip1_114_13872">
        <rect width="231" height="127.167" fill="white" transform="translate(24.5 15.1666)"/>
      </clipPath>
    </defs>
  </svg>
);