import {
  useRef,
  useEffect
} from 'react';

import type { Direction } from 'types';

import { KeyCode } from 'const';


type Handler = ( direction: Direction ) => void;


const pressedKeys: { [key: string]: boolean } = {};


export const useDirection = ( handler: Handler ) => {
  const handlerRef = useRef<Handler>(handler);


  useEffect(() => {
    const handleDirection = ( direction: Direction ) => {
      if (handlerRef.current) {
        handlerRef.current(direction);
      }
    };

    const handleDocumentKeyDown = ( evt: KeyboardEvent ) => {
      pressedKeys[evt.code] = true;

      const isUp = pressedKeys[KeyCode.ArrowUp] || pressedKeys[KeyCode.W];
      const isLeft = pressedKeys[KeyCode.ArrowLeft] || pressedKeys[KeyCode.A];
      const isDown = pressedKeys[KeyCode.ArrowDown] || pressedKeys[KeyCode.S];
      const isRight = pressedKeys[KeyCode.ArrowRight] || pressedKeys[KeyCode.D];
      

      if (isLeft && isUp) {
        handleDirection('left-up');
        return;
      }
      
      if (isLeft && isDown) {
        handleDirection('left-down');
        return;
      }

      if (isLeft) {
        handleDirection('left');
        return;
      }

      if (isRight && isUp) {
        handleDirection('right-up');
        return;
      }
      
      if (isRight && isDown) {
        handleDirection('right-down');
        return;
      }

      if (isRight) {
        handleDirection('right');
        return;
      }
      
      if (isUp) {
        handleDirection('up');
        return;
      }

      if (isDown) {
        handleDirection('down');
        return;
      }
    };

    const handleDocumentKeyUp = ( evt: KeyboardEvent ) => {
      pressedKeys[evt.code] = false;

      if (
        !Object.values(pressedKeys).some((isPressed) => isPressed)
      ) {
        handleDirection(null);
      }
    };


    document.addEventListener('keydown', handleDocumentKeyDown);
    document.addEventListener('keyup', handleDocumentKeyUp);

    return () => {
      document.removeEventListener('keydown', handleDocumentKeyDown);
      document.removeEventListener('keyup', handleDocumentKeyUp);
    }
  }, []);
};
