import { FC, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import classNames from 'classnames';

import 'swiper/scss';
import 'swiper/scss/pagination';
import styles from './_Slider.module.scss';

import type { ComponentProps, SlideProps } from './_Slider.types';

const sliderContent:SlideProps[] = [
  {
    img: {
      src: './img/slide-1.jpg',
      srcset: './img/slide-1@2x.jpg',
      alt: ' '
    },
    text: 'You are the ball and you are ready to do whatever it takes for your team to win! Try to stay on the field as long as possible, so that your team can earn as many RUNS as possible.'
  },
  {
    img: {
      src: './img/slide-2.jpg',
      srcset: './img/slide-2@2x.jpg',
      alt: ' '
    },
    text: 'On the playing field, you will encounter many catchers. Avoid them! If you get caught by them, you will immediately lose.'
  },
  {
    img: {
      src: './img/slide-3.jpg',
      srcset: './img/slide-3@2x.jpg',
      alt: ' '
    },
    text: 'Also, on the field, you will encounter squirrels and seagulls. Try not to collide with them. Upon collision, you will bounce off them and stop, thus risking getting caught by catchers.'
  },
  {
    img: {
      src: './img/slide-4.jpg',
      srcset: './img/slide-4@2x.jpg',
      alt: ' '
    },
    text: 'Also you may encounter bad turf and a battery - if you touch the bad turf, you’ll slow down, and if you touch the battery, you’ll receive a boost and immunity to catchers and animals.'
  },
  {
    img: {
      src: './img/slide-5.jpg',
      srcset: './img/slide-5@2x.jpg',
      alt: ' '
    },
    text: 'The number of RUNS is displayed at the top of the screen. The longer you stay on the field, the higher you will be on the leaderboard and will have a greater chance of winning prizes!'
  },
]



export const Slider: FC<ComponentProps> = ({
  className
}) => {
  const sliderRef = useRef(null);
  return <>
    <div className={ classNames(className, styles.component) }>
      <Swiper
        modules={[Pagination]}
        slidesPerView={1}
        pagination={{ 
          clickable: true,
         }}
        allowTouchMove={true}
        className={styles.swiper}
        ref={sliderRef}
      >
        {sliderContent.map((item, index) => (
          <SwiperSlide className={styles.slide} key={index}>
            <div className={styles.slideWrapper}>
              <div className={styles.slideImage}>
                <img src={item.img.src} srcSet={item.img.srcset} alt={item.img.alt} width='280' height='140'/>
              </div>
              <div className={styles.slideContent}>
                <p>{item.text}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  </>
};
