import { FC } from 'react';
import classNames from 'classnames';
import { Background, Button, Container, Title } from 'components';

import styles from './_ModalWrapper.module.scss';

import type { ComponentProps } from './_ModalWrapper.types';
import { IconGift } from 'icons';


export const ModalWrapper: FC<ComponentProps> = ({
  className,
  title,
  children,
  buttons,
  button,
  hasGift = false,
  onGiftClick,
  color = 'yellow'
}) => {
  return <>
    <Background className={classNames(styles.component, className)} color={color}>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.titleWrapper}>
            <Title className={styles.title} modifiers={['shadow', 'uppercase']}>{title}</Title>
            {hasGift && (
              <button className={styles.gift} aria-label="Watch prizes" onClick={onGiftClick}>
                <IconGift></IconGift>
              </button>
            )}
          </div>
          {children}
          {button && (
            <Button  className={styles.button} modifiers={button.modifiers} onClick={button.onClick}>{button.children}</Button>
          )}

          {
            buttons && (
              <div className={styles.buttons}>
                {buttons.map((button, index) => (
                  <Button  type={button.type} modifiers={button.modifiers} onClick={button.onClick} key={index}>{button.children}</Button>
                ))}
              </div>
            )
          }
        </div>
      </Container>
    </Background>
  </>
};
