import {
  createContext,
  ReactNode,
  FC,
  useState
} from 'react';

import type { Route } from 'types';


type Props = {
  children: ReactNode
};


export const RouteContext = createContext<Route>(null);
export const SetRouteContext = createContext<(route: Route) => void>(() => {});

export const RouteProvider: FC<Props> = ({
  children
}) => {
  const [ route, setRoute ] = useState<Route>(null);


  return <>
    <RouteContext.Provider value={ route } >
      <SetRouteContext.Provider value={ (route: Route) => setRoute(route) } >
        { children }
      </SetRouteContext.Provider>
    </RouteContext.Provider>
  </>
};