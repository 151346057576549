import { atom } from 'jotai'
import { CollisionData } from 'types'


export const playerDataAtom = atom<CollisionData>({
    x: 0,
    y:0,
    width: 0,
    height: 0,
    isTurbo: false
})