import {
  FC,
  memo,
  useEffect,
  useRef,
  useState
} from 'react';
import { useAtom } from 'jotai';
import {
  useTick,
  Container,
  AnimatedSprite
} from '@pixi/react';

import type { Direction } from 'types';
import type { ComponentProps } from './_Gull.types';

import { checkCollision } from 'helpers';

import {
  fieldCenterAtom,
  playerDataAtom,
  collidedEntityAtom
} from 'atoms';

import {
  TILES_COUNT,
  FPS
} from 'components/Game/Stage/_Stage';
import { DEFAUL_DISTANCE_MULTIPLIER } from 'const';


const BASE_SIZE = 70;
const COLLISON_REST_TIMER = 3


const getSpawnDirection = ( direction: Direction ) => {
  if (
    direction === 'right' ||
    direction === 'right-up' ||
    direction === 'right-down' || 
    direction === 'up'
  ) {
    return 'left';
  } else {
    return 'right';
  }
};


export const Gull: FC<ComponentProps> = memo(({
  texture,
  position,
  stageWidth,
  direction,
  stageHeight,
  setDestroyed
}) => {
  const [ , setCollidedEntity ] = useAtom(collidedEntityAtom);

  const size = BASE_SIZE * stageWidth / 320;
  const tileSize = stageWidth / TILES_COUNT;
  const gullOffset = tileSize * 2;
  const [movementDirection, setMovementDirection] = useState(1)
  const [ isCollided, setCollided ] = useState<boolean>(false);

  const [ playerData, ] = useAtom(playerDataAtom);
  const componentRef = useRef<any>();

  const [textureKey, setTextureKey] = useState(getSpawnDirection(direction))

  const tickerCounter = useRef(0)
  const [center,] = useAtom(fieldCenterAtom)

  const currentTexture = texture.animations[ textureKey ];
  const [currentPos, setCurrentPos] = useState({
    x: position.x - size/2,
    y: position.y - size/2
  })

  useEffect(() => {
    setCurrentPos(
      {
        x: position.x - size/2,
        y: position.y - size/2
      }
    )
  }, [position.x, position.y, size])

  useTick((tick) => {
    const time = tick / FPS;
    const distance  = tileSize * time * movementDirection

    if (isCollided) {
      tickerCounter.current += tick

      if (tickerCounter.current >=  COLLISON_REST_TIMER * FPS) {
        setCollided(false)
        tickerCounter.current = 0
        setTextureKey(movementDirection > 0 ? 'right' : 'left')
      } else {
        return
      }
    }

    if (currentPos.x >= position.x + gullOffset - size/2) {
      setMovementDirection(-1);
      setTextureKey(getSpawnDirection("right"))
    } 
    
    if (currentPos.x <= position.x - gullOffset - size/2) {
      setMovementDirection(1);
      setTextureKey(getSpawnDirection("left"))
    }

    setCurrentPos((current) => (
      {
        x: current.x + distance,
        y: current.y
      }
    ))
  })
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!componentRef.current || !playerData.ref || playerData.isTurbo) {
      return;
    }

    const collison = checkCollision(componentRef.current, playerData.ref);

    if (collison) {
      setCollidedEntity('gull');
      
      setCollided(collison);
      setTextureKey(movementDirection > 0 ? 'right_damage' : 'left_damage');
    }
  });

  useEffect(() => {
    const {x: playerX, y: playerY} = center
    const {x:  componentX, y: componentY} = position
    if (componentX===0 || componentY===0) return
    const xDistance = stageWidth * DEFAUL_DISTANCE_MULTIPLIER 
    const yDistance = stageHeight * DEFAUL_DISTANCE_MULTIPLIER 
    const deltaX = Math.abs(playerX - componentX)
    const deltaY = Math.abs(playerY - componentY)

    if (deltaX >= xDistance || deltaY >= yDistance) {
      setDestroyed(true)
    }
  }, [center, center.x, center.y, position, setDestroyed, stageHeight, stageWidth])



  return <>
    <Container
      position={ currentPos }
      width={ size }
      height={ size }
      anchor={ 0.5 }
      ref={componentRef}
    >
      <AnimatedSprite
        key={ textureKey }
        position={ [size/2 ,size/2] }
        width={ size }
        height={ size }
        anchor={ 0.5 } 
        textures={ currentTexture }
        animationSpeed={ 0.5 }
        isPlaying={ true }
      />
    </Container>
  </>
});
