import { FC } from 'react';
// import classNames from 'classnames';

import styles from './_Rotate.module.scss';

import type { ComponentProps } from './_Rotate.types';
import { Background } from 'components';
import { IconRotate } from 'icons';


export const Rotate: FC<ComponentProps> = ({
  className,
}) => {
  return <>
    <Background className={styles.component} color='overlay'>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.icon}>
            <IconRotate />
          </div>
          <p className={styles.text}>
            Please rotate the screen to continue playing
          </p>
        </div>
      </div>
    </Background>
  </>
};
