export const IconTouch = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="58"
    height="60"
    fill="none"
    viewBox="0 0 58 60"
  >
    <g filter="url(#filter0_d_2073_5628)">
      <g clipPath="url(#clip0_2073_5628)" filter="url(#filter1_i_2073_5628)">
        <rect width="56" height="56" x="1" y="1" fill="#FF0041" rx="5"></rect>
        <path
          fill="#fff"
          stroke="#B7002F"
          strokeLinecap="round"
          d="M27.997 27.252v.936l.778-.52 1.556-1.043a.75.75 0 011.066.25l.637 1.097.319.549.459-.438.918-.876a.751.751 0 011.223.285l.472 1.293.236.646.541-.424 1.084-.849a.75.75 0 011.211.591V30.286l-.002.036-.001.018v.019l.002.036.001.106v6a3.5 3.5 0 01-3.5 3.5h-5a3.48 3.48 0 01-2.243-.813l-.321.383.32-.383-.087-.074-.044-.037-.052-.026-.102-.052a.805.805 0 01-.18-.124l-7.36-6.692a.738.738 0 01.986-1.098l2.254 2.005c1.29 1.146 3.33.23 3.33-1.495V18.75a.75.75 0 011.5 0v8.502zm9.166-1.19a2.74 2.74 0 00-.6.205 2.743 2.743 0 00-3.165-1.134c-.213.07-.416.165-.603.281a2.74 2.74 0 00-2.798-.81V18.75a2.75 2.75 0 10-5.5 0v12.844l-2.254-2.004a2.738 2.738 0 00-3.661 4.072l7.361 6.692c.177.161.37.295.57.402A5.48 5.48 0 0029.998 42h5a5.5 5.5 0 005.5-5.5v-6c0-.049 0-.098-.002-.146a2.74 2.74 0 00.002-.104v-1.5a2.75 2.75 0 00-3.334-2.688zm-3.259-8.77l.354.354-.354-.353a1 1 0 00-.16 1.207H31.25a1 1 0 100 2h2.495a1 1 0 00.159 1.207l.353-.354-.353.354a1 1 0 001.414 0l1.389-1.389a.998.998 0 00.287-.818.998.998 0 00-.287-.818l-.353.353.353-.353-1.389-1.39-.353.354.353-.353a1 1 0 00-1.414 0zm-16.111 1.39a.998.998 0 00-.287.819.998.998 0 00.287.817l1.389 1.39a1 1 0 001.573-1.208h2.495a1 1 0 100-2h-2.495a1 1 0 00-1.573-1.207l-1.39 1.389z"
        ></path>
      </g>
      <rect
        width="57"
        height="57"
        x="0.5"
        y="0.5"
        stroke="#1A1023"
        rx="5.5"
        shapeRendering="crispEdges"
      ></rect>
    </g>
    <defs>
      <filter
        id="filter0_d_2073_5628"
        width="58"
        height="60"
        x="0"
        y="0"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="2"></feOffset>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2073_5628"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_2073_5628"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter1_i_2073_5628"
        width="56"
        height="56"
        x="1"
        y="1"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="-2"></feOffset>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 0.601904 0 0 0 0 0 0 0 0 0 0.153427 0 0 0 1 0"></feColorMatrix>
        <feBlend in2="shape" result="effect1_innerShadow_2073_5628"></feBlend>
      </filter>
      <clipPath id="clip0_2073_5628">
        <rect width="56" height="56" x="1" y="1" fill="#fff" rx="5"></rect>
      </clipPath>
    </defs>
  </svg>
);