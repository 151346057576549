import {
  useContext,
  useState,
  useEffect
} from 'react';

import styles from './App.module.scss';

import {
	RouteContext,
	SetRouteContext
} from 'context';

import {
  useScaling,
  useSetVH,
  useWindowResize
} from 'hooks';

import {
  Background,
  GameWrappper,
  Game
} from 'components';

import {
  LoaderScreen, Rotate
} from 'modals';

import { 
  Guide, 
  LeaderBoard, 
  Menu, 
  OtherGames, 
  Results, 
  Save 
} from 'screens';
import { isMobileLandscape } from 'helpers';



function App() {
  const route = useContext(RouteContext);
	const setRoute = useContext(SetRouteContext);

  const [loading, setLoading] = useState(true);
	const [loaded, setLoaded] = useState(false);
  const [isRotateVissiable, setRotateVissiable] = useState(false)


  useScaling();
  useSetVH();

  useEffect(() => {
    setLoaded(true)
    setRoute('menu');
    
    (window as any).catched = () => {
      setRoute('result')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useWindowResize(() => {
    if (isMobileLandscape()) {
      setRotateVissiable(true)
    } else {
      setRotateVissiable(false)
    }
  })

  const renderScreen = () =>{
    switch(route) {
      case 'menu': {
        return <Menu />
      }
      case 'otherGames': {
        return <OtherGames />
      }
      case 'guide': {
        return <Guide />
      }
      case 'game': {
        return <Game />
      }
      case 'leaderboard': {
        return <LeaderBoard />
      }
      case 'result': {
        return <Results />
      }
      case 'save': {
        return <Save />
      }
    }
  }

  return (
    <>
      <Background className={styles.pageWrapper} color="red">
        <GameWrappper>
          
          {loading &&
            (<LoaderScreen 
              className={styles.loader}
              wrapperClassName={styles.loaderWrapper}
              titleText={'loading'} 
              loaded={loaded} 
              loading={loading} 
              setLoading={setLoading} 
              animationDuration={3000} />)}
          {renderScreen()}
        </GameWrappper>
      </Background>
      {isRotateVissiable && <Rotate />}
    </>
  );
}

export default App;
