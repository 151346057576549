import { FC, useContext } from 'react';

import styles from './_Confirm.module.scss';

import type { ComponentProps } from './_Confirm.types';
import { Background, Button, CardWrapper, Title } from 'components';
import { SetRouteContext } from 'context';
import { useAtom } from 'jotai';
import { ScoreAtom, defaultScore } from 'atoms/_scoreAtom';


export const Confirm: FC<ComponentProps> = ({
  className,
  onClick
}) => {
  const setRoute = useContext(SetRouteContext);
  const [, setScore] = useAtom(ScoreAtom)
  return <>
    <Background className={styles.component} color='overlay'>
      <div className={styles.wrapper}>
        <Title className={styles.title} modifiers={['shadow', 'uppercase']}>are you sure?</Title>
        <div className={styles.buttons}>
          <Button className={styles.button} onClick={() => {
            onClick()
            setRoute('save')
          }}>save results</Button>
          <Button className={styles.button} modifiers={['yellow']} onClick={() => {
            onClick()
            setRoute('menu')
            setScore(defaultScore)
          }}>back to menu</Button>
        </div>
        <CardWrapper className={styles.textWrapper}>
          <div className={styles.texts}>
            <p>If you don’t save your score, you won’t be able to win cool prizes from Batery!</p>
          </div>
        </CardWrapper>
      </div>
    </Background>
  </>
};
