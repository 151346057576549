import { FC } from 'react';
import classNames from 'classnames';

import styles from './_LoaderScreen.module.scss';

import type { ComponentProps } from './_LoaderScreen.types';
import { Background, Loader, Title } from 'components';


export const LoaderScreen: FC<ComponentProps> = ({
  color = 'white',
  className,
  loaded,
  loading,
  animationDuration = 3000,
  animationLoadingPercent = 59,
  setLoading,
  titleText,
  titleSize = 'm',
  wrapperClassName,
  hasVideo,
  titleModifiers = ['shadow', 'uppercase']
}) => {
  return <>
   <Background className={classNames(styles.component, className)} color={color}>
      {hasVideo && (
        <div className={styles.video}>
          <video autoPlay muted loop playsInline>
            <source src="./video/gameloader.mp4" type="video/mp4" />
          </video>
        </div>
      )}
      <div className={classNames(styles.wrapper, wrapperClassName)}>
        <Title modifiers={titleModifiers} size={titleSize}>{titleText}</Title>
        <Loader loaded={loaded} loading={loading} setLoading={setLoading} animationDuration={animationDuration}  animationLoadingPercent={animationLoadingPercent}  />
      </div>
   </Background>
  </>
};
