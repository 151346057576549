import {
  FC,
  useState,
  useRef,
  useEffect
} from 'react';
import {
  useApp,
  useTick
} from '@pixi/react';

import type {
  Direction,
  State as BallState
} from 'types';
import type { ComponentProps } from './_Stage.types';

import {
  BATTERY_ENTITIE,
  MAIN_ENTITIES,
  DirectionDiff
} from 'const';

import { getRandomNum } from 'helpers';

import {
  movementDistanceAtom,
  collidedEntityAtom,
  playerDataAtom,
  entityTexturesAtom,
} from 'atoms';

import { Field } from './Field';
import { Player } from './Player';
import { Spawn } from './Spawn';
import { useAtom } from 'jotai';
import { Runner } from './Spawn/Entity';


export const TILES_COUNT = 8;

// дефолт тикера pixi
// зависит от нагрузки на устройство
export const FPS = 60;

const VELOCITY_FAST = 2;
const VELOCITY_MAX = 4;
const VELOCITY_LOSS_QUOTIENT = 0.25;

const REBOUND_DURATION = 500;
const TURBO_MODE_DURATION = 8000;

const BATTERY_SPAWN_INTERVAL = 5;
const RUNS_INTERVAL = 5;
const ENTITY_INTRAVAL_REDUCER = 0.1
const ENTITY_INTREVAL = 3.2


const getAcceleration = ({
  isDeceleration,
  isTurbo
}: {
  isDeceleration: boolean;
  isTurbo: boolean;
}) => {
  if (isTurbo) {
    return 2;
  }

  if (isDeceleration) {
    return -2;
  }

  return 1;
};

const getVelocity = ({
  velocity,
  time,
  isTurbo,
  isDeceleration
}: {
  velocity: number,
  time: number,
  isTurbo: boolean;
  isDeceleration: boolean;
}) => {
  const newVelocity = velocity + getAcceleration({ isTurbo, isDeceleration }) * time;

  if (newVelocity < 0) {
    return 0;
  }

  if (newVelocity > VELOCITY_MAX) {
    return VELOCITY_MAX;
  }

  return newVelocity;
};

const getPlayerState = ({
  movementDirection,
  velocity,
  isTurbo,
  isDamaged
}: {
  movementDirection: Direction,
  velocity: number,
  isTurbo: boolean;
  isDamaged: boolean;
}): BallState => {
  if (isDamaged) {
    return 'idle_damage';
  }

  if (!movementDirection || velocity === 0) {
    if (isTurbo) {
      return 'idle_turbo';
    }

    return 'idle';
  }

  if (movementDirection && velocity >= VELOCITY_MAX) {
    if (isTurbo) {
      return 'fast_turbo';
    }

    return 'fast';
  }
};

const getRunnerPostion = (
  {size}:
  {
    size: {
      width: number;
      height: number;
    },
  } ) => {
    const {width, height} = size;
    const positions = {
      'up': {
        x: width / 2,
        y: 0
      },
      'down': {
        x: width / 2,
        y: height
      },
      'left-up': {
        x: 0,
        y: -height * 0.25
      },
      'left-down': {
        x: 0,
        y: height + height * 0.25
      },
      'right-up': {
        x: width,
        y: -height * 0.25
      },
      'right-down': {
        x: width,
        y: height + height * 0.25
      },
    }

    function getRandomDirection(): Exclude<Direction, null | 'left' | 'right'> {
      const directions: Exclude<Direction, null | 'left' | 'right'>[] = ['left-up', 'up', 'right-up', 'right-down', 'down', 'left-down'];
      const randomIndex = Math.floor(Math.random() * directions.length);
      return directions[randomIndex];
    }

    return positions[getRandomDirection()]
}

export const Stage: FC<ComponentProps> = ({
  size,
  movementDirection,
  setScore,
  isPlaying,
  onLose
}) => {
  const [ movementDistance, setMovementDistance ] = useAtom(movementDistanceAtom);
  const [ collidedEntity, setCollidedEntity ] = useAtom(collidedEntityAtom);
  const [ playerData, setPlayerData ] = useAtom(playerDataAtom);
  const [ entityTextures,] = useAtom(entityTexturesAtom);
  const isSpawned = useRef(false);

  const entitiesInterval = useRef(ENTITY_INTREVAL)

  const tickerCounter = useRef({
      entitie: 0,
      batterie: 0,
      runs: 0,
      totalTicks: 0
    })

  const [ entities, setEntites ] = useState<JSX.Element[]>([]);
  const [ batteries, setBatteries] =  useState<JSX.Element[]>([]);

  const currentMovementDirectionRef = useRef<Direction>(null);
  const lastMovementDirectionRef = useRef<Direction>(null);

  const isDirectionChangedRef = useRef<boolean>(false);
  const directionDiffRef = useRef<number>(0);

  const velocityRef = useRef<number>(0);

  const collisionTimestampRef = useRef<number>(0);
  const startIdleDelayTimestampRef = useRef<number>(0);
  const startTurboTimeRef = useRef<number>(0);

  const tileSize = size.width / TILES_COUNT;

  const isReboundCollision = collidedEntity === 'gull' || collidedEntity === 'squirrel';

  const refRuns =useRef(0)

  const currentMovementDirection = currentMovementDirectionRef.current || lastMovementDirectionRef.current;


  // TODO удалить перед релизом. Нужно для расширения пикси в браузере
  const app = useApp();
  (globalThis as any).__PIXI_APP__ = app;


  const handleLose = () => {
    onLose();

    setScore((prevState) => ({
      ...prevState, 
      score: Number((tickerCounter.current.totalTicks / FPS * 10).toFixed(0))
    }));
  };


  useEffect(() => {
    if (isReboundCollision && movementDirection) {
      const [ targetDirection ] = Object.entries(DirectionDiff[movementDirection]).find(([, value]) => value === 4) || [];

      currentMovementDirectionRef.current = targetDirection as Direction || null;
      lastMovementDirectionRef.current = null;

      return;
    }

    currentMovementDirectionRef.current = movementDirection;

    if (
      currentMovementDirectionRef.current &&
      lastMovementDirectionRef.current &&
      lastMovementDirectionRef.current !== currentMovementDirectionRef.current &&
      velocityRef.current > 0
    ) {
      isDirectionChangedRef.current = true;

      directionDiffRef.current = DirectionDiff[lastMovementDirectionRef.current][currentMovementDirectionRef.current];
      
      if (Math.abs(directionDiffRef.current) > 1) {
        const targetDiff = directionDiffRef.current < 0 ? -1 : 1;
        const [ targetDirection ] = Object.entries(DirectionDiff[lastMovementDirectionRef.current]).find(([, value]) => value === targetDiff) || [];

        currentMovementDirectionRef.current = targetDirection as Direction || null;

        if (Math.abs(directionDiffRef.current) > 3) {
          currentMovementDirectionRef.current = lastMovementDirectionRef.current;
        }
      }
    }

    lastMovementDirectionRef.current = currentMovementDirectionRef.current;
  });


  useTick((tick) => {
    if (!isPlaying) return;

    const time = 1 / FPS + getRandomNum(0, 0.01);

    if (isPlaying && !isSpawned.current) {
      isSpawned.current = true;
      setEntites(
        [...entities,
          <Runner 
            texture={entityTextures['runner']}
            position={getRunnerPostion({size})}
            stageWidth={size.width}
            stageHeight={size.height}
            handleLose={handleLose}
          />
        ]);
    }

    if (Date.now() - startIdleDelayTimestampRef.current >= 200) {
      startIdleDelayTimestampRef.current = 0;
    }

    if (isReboundCollision) {
      if (!collisionTimestampRef.current) {
        collisionTimestampRef.current = Date.now();
      } else {
        if (Date.now() - collisionTimestampRef.current >= REBOUND_DURATION) {
          setCollidedEntity(null);

          velocityRef.current = 0;

          collisionTimestampRef.current = 0;
          startIdleDelayTimestampRef.current = Date.now();
        } else {
          const reboundDistanceGain = velocityRef.current > VELOCITY_FAST ? 2 : 1;

          setMovementDistance(reboundDistanceGain * tileSize * time);
        }
      }
    } else {
      const velocityQuotient = isDirectionChangedRef.current && !playerData.isTurbo ? (1 - VELOCITY_LOSS_QUOTIENT) : 1;
  
      if (playerData.isTurbo) {
        if (!startTurboTimeRef.current) {
          startTurboTimeRef.current = Date.now();
        }
    
        if (Date.now() - startTurboTimeRef.current > TURBO_MODE_DURATION) {
          setPlayerData((current) => ({
            ...current,
            isTurbo: false
          }));
  
          startTurboTimeRef.current = 0;
        }
      }
  
      velocityRef.current = playerData.isTurbo ?
        VELOCITY_MAX
        :
        getVelocity({
          velocity: velocityRef.current * velocityQuotient,
          time,
          isTurbo: playerData.isTurbo,
          isDeceleration:
            !movementDirection ||
            Math.abs(directionDiffRef.current) > 3 ||
            (collidedEntity === 'mud' && velocityRef.current > 1)
        });

      if (!startIdleDelayTimestampRef.current) {
        setMovementDistance(tileSize * velocityRef.current * time);
      }
    }

    tickerCounter.current.entitie += tick
    tickerCounter.current.batterie += tick
    tickerCounter.current.runs += tick
    tickerCounter.current.totalTicks += tick

    if (tickerCounter.current.runs >= RUNS_INTERVAL* FPS) {
      tickerCounter.current.runs = 0
      setScore((prevState) => ({
        ...prevState, 
        runs: prevState.runs + 1
      }))
      refRuns.current++
      if (entitiesInterval.current >= 0.5 && refRuns.current % 2 === 0 &&  refRuns.current !== 0) {
        entitiesInterval.current -= ENTITY_INTRAVAL_REDUCER 
      }
    }

    if (tickerCounter.current.entitie >= entitiesInterval.current * FPS) {
      tickerCounter.current.entitie = 0

      setEntites(
        [...entities,
          <Spawn
            key={entities.length + 1} 
            entities={MAIN_ENTITIES}
            { ...size } 
            handleLose={ handleLose }
          />
        ]);
    }

    if ( tickerCounter.current.batterie >= BATTERY_SPAWN_INTERVAL * FPS) {
      tickerCounter.current.batterie = 0

      setBatteries([
        ...batteries,
        <Spawn
          key={batteries.length + 1} 
          entities={[BATTERY_ENTITIE]}
          { ...size } 
          handleLose={ handleLose }
        />
      ]);
    }
    
    isDirectionChangedRef.current = false;
    directionDiffRef.current = 0;

    if (velocityRef.current === 0) {
      currentMovementDirectionRef.current = null;
      lastMovementDirectionRef.current = null;
    }

    if (velocityRef.current <= 1 && collidedEntity === 'mud') {
      setCollidedEntity(null);
    }
  });
  

  return <>
    <Field
      { ...size }
      tileSize={ tileSize }
      movementDistance={ movementDistance }
      movementDirection={ currentMovementDirectionRef.current || lastMovementDirectionRef.current }
    >
        { entities.map((entitie) => entitie) }
        { batteries.map((batterie) => (batterie)) }
    </Field>

    <Player
      stageSize={ size }
      movementDirection={ currentMovementDirection }
      state={ getPlayerState({
        movementDirection: currentMovementDirection,
        velocity: velocityRef.current,
        isTurbo: playerData.isTurbo,
        isDamaged: isReboundCollision
      }) }
    />
  </> 
};
