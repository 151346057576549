import { FC, useEffect, useRef, useState } from 'react';

import type { ComponentProps } from './_Runner.types';
import { AnimatedSprite, Container, Graphics, useTick } from '@pixi/react';
import { Direction } from 'types';
import { useAtom } from 'jotai';
import { fieldCenterAtom, playerDataAtom } from 'atoms';
import { FPS, TILES_COUNT } from 'components/Game/Stage/_Stage';
import { checkCollision } from 'helpers';
import { RUNNER_DISTANCE_MULTIPLIER } from 'const';

const BASE_SIZE = 100;

type ObjectParams = {
  x: number;
  y: number;
  width: number;
  height: number;
}

function getDirection(obj1: ObjectParams, obj2: ObjectParams): Direction {
  const {x: x1, y: y1, width: width1, height: height1} = obj1;
  const {x: x2, y: y2, width: width2, height: height2} = obj2;

  const centerX1 = x1 + width1/2
  const centerY1 = y1 + height1/2

  if (centerY1 < y2) {
    if (centerX1 < x2) {
      return 'left-up';
    } else if (centerX1 > x2 + width2) {
      return 'right-up';
    } else {
      return 'up';
    }
  } else if (centerY1 > y2 + height2) {
    if (centerX1 < x2) {
      return 'left-down';
    } else if (centerX1 > x2 + width2) {
      return 'right-down';
    } else {
      return 'down';
    }
  } else {
    if (centerX1 < x2) {
      return 'left';
    } else if (centerX1 > x2 + width2) {
      return 'right';
    }
  }

  return 'up'; // Если ни одно из условий не выполнено, по умолчанию смотрим вверх
}

export const Runner: FC<ComponentProps> = ({
  texture,
  position, 
  stageWidth,
  handleLose,
  setDestroyed,
  stageHeight
}) => {
  const [ playerData, ] = useAtom(playerDataAtom);
  const tileSize = stageWidth / TILES_COUNT;
  const componentRef = useRef<any>();
  const size = BASE_SIZE * stageWidth / 320;

  const [textureKey, setTextureKey] = useState('down')
  const currentTexture = texture.animations[ textureKey ];
  const [ isCollided,  setCollided ] = useState<boolean>(false);
  const [center,] = useAtom(fieldCenterAtom);


  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!texture || !componentRef.current || !playerData.ref) return
    setTextureKey(getDirection(componentRef.current.getBounds(true), playerData.ref.getBounds()) || 'up')
  })

  const [currentPos, setCurrentPos] = useState({
    x: position.x - size/2,
    y: position.y - size/2
  })

  useEffect(() => {
    setCurrentPos(
      {
        x: position.x - size/2,
        y: position.y - size/2
      }
    )
  }, [position.x, position.y, size])

  useTick((tick) => {
    const time = tick / FPS;
    const {x: targetX, y: targetY} =  playerData.ref.getBounds()
    const {x, y} = componentRef.current.getBounds()

    const deltaX = targetX - x;
    const deltaY = targetY - y;
    const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
    const speed = tileSize * 2 * time
    const ratio = speed / distance;

    if (!isCollided) {
      setCurrentPos((current) => (
        {
          x: current.x + deltaX * ratio,
          y: current.y + deltaY * ratio
        }
      ))
    }
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!componentRef.current || !playerData.ref || playerData.isTurbo) {
      return;
    }

    const collison = checkCollision(componentRef.current, playerData.ref);

    if (!isCollided && collison) {
      setCollided(collison);
      handleLose()
    }
  });

  useEffect(() => {
    const {x: playerX, y: playerY} = center
    const {x:  componentX, y: componentY} = position
    if (componentX===0 || componentY===0) return
    const xDistance = stageWidth * RUNNER_DISTANCE_MULTIPLIER 
    const yDistance = stageHeight * RUNNER_DISTANCE_MULTIPLIER 
    const deltaX = Math.abs(playerX - componentX)
    const deltaY = Math.abs(playerY - componentY)

    if (deltaX >= xDistance || deltaY >= yDistance) {
      if (setDestroyed) {
        setDestroyed(true)
      }
    }
  }, [center, center.x, center.y, position, setDestroyed, stageHeight, stageWidth])

  return <>
    <Container width={size*0.75} height={size*0.75}  ref={componentRef} position={[currentPos.x + size*0.5 - size*0.75/2, currentPos.y + size*0.5 - size*0.75/2]}
    >
      <Graphics width={size*0.75} height={size*0.75}  position={ [0,0] } draw={(g) => {
            g.clear();
            g.beginFill(0xff00bb, 0.0001);
            g.drawRect(
              0,
              0,
              size*0.75,
              size*0.75
            );
            g.endFill()
      }} /> 
    </Container>

    <Container  
      position={ currentPos }
      width={ size }
      height={ size }
      anchor={ 0.5 }  
    >
      <AnimatedSprite
        key={ textureKey }
        position={ [size/2 ,size/2] }
        width={ size }
        height={ size }
        anchor={ 0.5 } 
        textures={ currentTexture }
        animationSpeed={ 0.5 }
        isPlaying={ true }
      />
    </Container>
  </>
};
