import { FC } from 'react';
import classNames from 'classnames';

import styles from './_Runs.module.scss';

import type { ComponentProps } from './_Runs.types';


export const Runs: FC<ComponentProps> = ({
  className,
  runs
}) => {
  return <>
    <div className={ classNames(className, styles.component) }>
      <div className={styles.wrapper}>
        <p>Runs: 
          <span>{runs}</span>
        </p>
      </div>
    </div>
  </>
};
