import type { Direction } from 'types';


export const DirectionDiff: {
  [key in Direction as string]: {
    [key in Direction as string]: number
  }
} = {
  'up': {
    'right-up': 1,
    'right': 2,
    'right-down': 3,
    'down': 4,
    'left-down': -3,
    'left': -2,
    'left-up': -1
  },
  'right-up': {
    'right': 1,
    'right-down': 2,
    'down': 3,
    'left-down': 4,
    'left': -3,
    'left-up': -2,
    'up': -1
  },
  'right': {
    'right-down': 1,
    'down': 2,
    'left-down': 3,
    'left': 4,
    'left-up': -3,
    'up': -2,
    'right-up': -1
  },
  'right-down': {
    'down': 1,
    'left-down': 2,
    'left': 3,
    'left-up': 4,
    'up': -3,
    'right-up': -2,
    'right': -1
  },
  'down': {
    'left-down': 1,
    'left': 2,
    'left-up': 3,
    'up': 4,
    'right-up': -3,
    'right': -2,
    'right-down': -1
  },
  'left-down': {
    'left': 1,
    'left-up': 2,
    'up': 3,
    'right-up': 4,
    'right': -3,
    'right-down': -2,
    'down': -1
  },
  'left': {
    'left-up': 1,
    'up': 2,
    'right-up': 3,
    'right': 4,
    'right-down': -3,
    'down': -2,
    'left-down': -1
  },
  'left-up': {
    'up': 1,
    'right-up': 2,
    'right': 3,
    'right-down': 4,
    'down': -3,
    'left-down': -2,
    'left': -1
  }
};
