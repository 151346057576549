import {
  FC,
  useId
} from 'react';
import classNames from 'classnames';

import styles from './_FormField.module.scss';

import type { ComponentProps } from './_FormField.types';
import { IMaskInput } from 'react-imask';


export const FormField: FC<ComponentProps> = ({
  className,
  name,
  value,
  label,
  mask,
  placeholder,
  pattern,
  error,
  inputMode = 'text',
  isInvalid,
  isIdField,
  isLabelHidden,
  onChange,
  onBlur,
  onAccept,
  onComplete
}) => {
  const id = useId();

  return <>
    <div className={classNames(
      className,
      styles.component,
    )}>
      <label
        className={ classNames(styles.label,  isLabelHidden && 'visually-hidden')}
        htmlFor={ id }
      >
        { label }
      </label>

      {!!mask ? (
        <IMaskInput
          className={classNames(styles.input, isInvalid && styles.input_invalid)}
          id={id}
          name={name}
          value={value}
          placeholder={placeholder}
          mask={mask}
          radix="."
          unmask={false}
          inputMode={inputMode}
          type={'text'}
          onBlur={onBlur}
          onAccept={onAccept}
          onComplete={onComplete}
        />
      ) : (
        <input
          className={classNames(styles.input, isInvalid && styles.input_invalid)}
          id={id}
          name={name}
          value={value}
          placeholder={placeholder}
          pattern={pattern}
          inputMode={inputMode}
          type={'text'}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}
      {isInvalid && error && <span className={styles.error}>{error}</span>}
    </div>
  </>
};