import { FC } from 'react';
import classNames from 'classnames';

import styles from './_Button.module.scss';

import type { ComponentProps } from './_Button.types';

export const Button: FC<ComponentProps> = ({
  className,
  children,
  type = 'button',
  modifiers = [],
  onClick,
  isDisabled,
  href,
  isExternal
}) => {
  const TagName = href ? 'a' : 'button';

  return <>
    <TagName 
      type={href ? undefined : type}
      className={classNames(
        className,
        styles.component,
        modifiers.map((modifier) => styles[`component_${ modifier }`]),
        isDisabled ?  styles[`component_isDisabled`] : ''
      )}  
      onClick={onClick}
      disabled={href ? undefined : isDisabled}
      href={href}
      rel={isExternal ? "nofollow noopener noreferrer" : ''}
      target={isExternal ? "_blank" : ''}
    >
      <span className={styles.body}>
        { children }
      </span>

      {!modifiers.some((mod) => mod === 'straight') ? (
        <>
          <span className={styles.innerShadow} />
          <span className={styles.background} />
          <span className={styles.backgroundEar}>
            <svg width="6" height="27" viewBox="0 0 6 27" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M3.16641 24.0046C3.07305 25.6851 1.68311 27 0 27V27H4.5L5.70681 5.27739C5.78458 3.87759 5.28021 2.58625 4.40937 1.63135L3.16641 24.0046Z" fill="CurrentColor"/>
            </svg>
          </span>
        </>
      )  : null }
    </TagName>
  </>
};
