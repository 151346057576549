import {
  FC,
  useState,
  useRef,
  useEffect
} from 'react';
import { useAtom } from 'jotai';
import {
  AnimatedSprite,
  Container
} from '@pixi/react';

import type { Direction } from 'types';
import type { ComponentProps } from './_Squirrel.types';

import { checkCollision } from 'helpers';

import {
  fieldCenterAtom,
  playerDataAtom,
  collidedEntityAtom
} from 'atoms';
import { DEFAUL_DISTANCE_MULTIPLIER } from 'const';


const BASE_SIZE = 70;

const getSquirrelSpawnDirection = (mounntDirection: Direction) => {
  if (mounntDirection === 'right' ||
      mounntDirection === 'right-up' ||
      mounntDirection === 'right-down' || 
      mounntDirection === 'up'
      ) {
        return 'right'
      } else {
        return 'left'
      }
}


export const Squirrel: FC<ComponentProps> = ({texture, tilePosition, stageWidth, stageHeight, direction, setDestroyed}) => {
  const [ playerData, ] = useAtom(playerDataAtom);
  const [ , setCollidedEntity ] = useAtom(collidedEntityAtom);

  const [ currentTexture, setCurrentTexture] = useState<any>(texture.animations[ `squirrel-${getSquirrelSpawnDirection(direction)}` ]);

  const componentRef = useRef<any>();
  const spriteRef = useRef<any>();
  const [center,] = useAtom(fieldCenterAtom)

  const size = BASE_SIZE * stageWidth / 320;


  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!componentRef.current || !playerData.ref || playerData.isTurbo) {
      return;
    }

    const collison = checkCollision(componentRef.current, playerData.ref);

    if (collison) {
      setCollidedEntity('squirrel');

      setCurrentTexture(texture.animations[ `squirrel-collapsed-${getSquirrelSpawnDirection(direction)}` ]);
    }
  })

  useEffect(() => {
    const {x: playerX, y: playerY} = center
    const {x:  componentX, y: componentY} = tilePosition
    if (componentX===0 || componentY===0) return
    const xDistance = stageWidth * DEFAUL_DISTANCE_MULTIPLIER 
    const yDistance = stageHeight * DEFAUL_DISTANCE_MULTIPLIER 
    const deltaX = Math.abs(playerX - componentX)
    const deltaY = Math.abs(playerY - componentY)

    if (deltaX >= xDistance || deltaY >= yDistance) {
      setDestroyed(true)
    }
  }, [center, center.x, center.y, setDestroyed, stageHeight, stageWidth, tilePosition])

  useEffect(() => {    
    setCurrentTexture(texture.animations[ `squirrel-${getSquirrelSpawnDirection(direction)}` ])
  }, [direction, texture.animations])

  useEffect(() => {
    spriteRef.current.play()
  }, [currentTexture])


  return <>
    <Container  
      position={ [tilePosition.x - size/2, tilePosition.y - size/2] }  
      ref={componentRef}
      width={ size }
      height={ size }
      anchor={ 0.5 }  
    >
      <AnimatedSprite
        textures={ currentTexture }
        width={ size }
        height={ size }
        position={[size/2,size/2]}
        anchor={ 0.5 } 
        animationSpeed={ 0.5 }
        isPlaying={ true }
        ref={spriteRef}
      />
    </Container>
  </>
};
