import { useIsomorphicLayoutEffect } from './index'
import { useAtom } from 'jotai'
import { deviceAtom, DeviceType } from 'atoms/_deviceAtom'

const BASE_FONT_SIZE = 12

const DEFAULT_DESKTOP_HEIGHT = 600
const BREAKPOINT_DESKTOP_HEIGHT = 769

const DEFAULT_TABLET_HEIGHT = 1024
const BREAKPOINT_TABLET_HEIGHT = 1023

let isVhScaling = false

const deviceSize = {
  mobileS: 320,
  mobileM: 390,
  mobileL: 480,
  tabletS: 768,
  tablet: 1024,
  laptop: 1200,
  desktop: 1440,
  fhd: 1920,
  uhd: 2560
}

const breakpointsArray = [
  {
    size: deviceSize.fhd,
    upscaleSize: deviceSize.laptop
  },
  {
    size: deviceSize.laptop,
    upscaleSize: deviceSize.tablet
  },
  {
    size: deviceSize.tablet,
    noScaleSize: deviceSize.tablet,
  },
  {
    size: deviceSize.tabletS - 1,
    noScaleSize: undefined,
    upscaleSize: deviceSize.tabletS
  },
  {
    size: deviceSize.mobileS,
  }
]

const getScaleFontSize = (
  windowWidth: number,
  minSize?: number,
  maxSize?: number
) => {
  const currentBreakpoint =
    breakpointsArray.find((item) => item.size < windowWidth) ||
    breakpointsArray[breakpointsArray.length - 1]
  const contentWidth = currentBreakpoint.noScaleSize
    ? currentBreakpoint.size
    : windowWidth <= deviceSize.uhd
    ? windowWidth
    : deviceSize.uhd

  const breakpointSize =
    currentBreakpoint.noScaleSize ||
    currentBreakpoint.upscaleSize ||
    currentBreakpoint.size

  let size = (contentWidth / breakpointSize) * BASE_FONT_SIZE


  if (window.innerHeight < window.innerWidth * BREAKPOINT_DESKTOP_HEIGHT / deviceSize.tablet) {
    isVhScaling = true;
  }

  if (window.innerHeight < window.innerWidth * BREAKPOINT_TABLET_HEIGHT / deviceSize.tabletS) {
    isVhScaling = true;
  }

  if (minSize) {
    size = size < minSize ? minSize : size
  }
  if (maxSize) {
    size = size < maxSize ? size : maxSize
  }

  return size.toFixed(2)
}

export const useScaling = () => {
  const [, setDevice] = useAtom(deviceAtom)

  useIsomorphicLayoutEffect(() => {
    const handleWindowResize = () => {
      const viewportWidth = window.innerWidth
      const htmlElement = document.querySelector('html')
      if (htmlElement) {
        const globalFontSize =
          viewportWidth !== null
            ? Number(getScaleFontSize(viewportWidth))
            : BASE_FONT_SIZE
        htmlElement.style.fontSize = `${globalFontSize}px`
      }

      if (isVhScaling &&  viewportWidth >= deviceSize.tabletS && htmlElement) {
        const globalFontSize = (BASE_FONT_SIZE  / DEFAULT_TABLET_HEIGHT * 100)
        htmlElement.style.fontSize = `${globalFontSize}vh`
      }

      if (isVhScaling &&  viewportWidth >= deviceSize.tablet && htmlElement) {
        const globalFontSize = (BASE_FONT_SIZE / DEFAULT_DESKTOP_HEIGHT * 100)
        htmlElement.style.fontSize = `${globalFontSize}vh`
      }

      let device: DeviceType = 'desktop'
      if (window.innerWidth <= deviceSize.tabletS) {
        device = 'mobile'
      } else if (window.innerWidth <= deviceSize.tablet) {
        device = 'tablet'
      }
      setDevice(device)
    }

    window.addEventListener('resize', handleWindowResize)
    handleWindowResize()
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])
}