import { ChangeEvent, FC, FormEvent, useContext, useState } from 'react';
import classNames from 'classnames';

import styles from './_Save.module.scss';

import type { ComponentProps } from './_Save.types';

import {ModalWrapper, SaveForm} from 'components';
import { SetRouteContext } from 'context';
import { setUserData } from 'api/user';
import { GAME_ID } from 'const';
import { convertToNumber } from 'helpers';
import { useAtom } from 'jotai';
import { ScoreAtom, defaultScore } from 'atoms/_scoreAtom';
import { LoaderScreen } from 'modals/LoaderScreen';

export const Save: FC<ComponentProps> = ({
  className,
}) => {
  
  const setRoute = useContext(SetRouteContext);

  const [loading, setLoading] = useState(false);
	const [loaded, setLoaded] = useState(false);

  const [phone, setPhone] = useState('')
  const [validity, setValidity] = useState({
    phone: true,
    agree: true
  })
  const [checked, setChecked] = useState(true)
  const [score, setScore] = useAtom(ScoreAtom)

  const handlePhoneChange = (value: string) => {
    setPhone(value)
    checkValidity('phone', value)
  }

  const checkValidity = (key: string, value: string) => {
    let valid = true;

    if (key === 'phone') {
      valid = value.length === 16
    }

    setValidity((prevState) => {
      return {
        ...prevState,
        [key]: valid
      }
    })

    return valid
  }

  const handleCheckbox = (evt: ChangeEvent<HTMLInputElement>) => {
    setChecked(evt.target.checked)
    setValidity((prevState) => {
      return {
        ...prevState,
        agree: evt.target.checked
      }
    })
  }

  const handleSumbitClick = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault()

    if (!checkValidity('phone', phone) || !checked) return

    setLoading(true)

    setUserData({
      gameID: GAME_ID,
      isLocalGameReg: true,
      phone: convertToNumber(phone),
      score: score.score,
      username: ''
    }).then(() => {
      setLoaded(true)

      setTimeout(() => {
        setRoute('menu')
        setScore(defaultScore)
      }, 3000)
    })
  }

  const TitleText = () => {
    return <><span className={styles.shrink}>Sa</span>ving</>
  }


  return <>
    <form className={styles.component} onSubmit={handleSumbitClick}>
      <ModalWrapper className={ classNames(className,)} title={'save results'} buttons={[
        {
          children: 'back',
          modifiers: ['yellow'],
          onClick: () => setRoute('result')
        },
        {
          children: 'confirm',
          type: 'submit',
        }
      ]}>
        <SaveForm 
          phone={phone} 
          validity={validity} 
          handlePhoneChange={handlePhoneChange}
          handleCheckbox={handleCheckbox}
          checked={checked}
          />
      </ModalWrapper>
    </form>  

    {loading ? (<LoaderScreen className={styles.modal} titleModifiers={['shadow-2', 'uppercase']} titleText={TitleText()} titleSize='xxl' loaded={loaded} loading={loading} setLoading={setLoading} animationDuration={3000} color='overlay' />) : null }
  </>
};
