import { FC, FormEvent, useState } from 'react';
import classNames from 'classnames';

import styles from './_LeaderBoardCard.module.scss';

import type { ComponentProps } from './_LeaderBoardCard.types';
import { CardWrapper, FormField, ScrollContainer, SubmitButton } from 'components';
import { IconSearch } from 'icons';
import { convertToNumber, formatPhoneNumber } from 'helpers';
import { useAtom } from 'jotai';
import { UserDataAtom } from 'atoms/_userAtom';
import { getUserData } from 'api/user';
import { GAME_ID } from 'const';


export const LeaderBoardCard: FC<ComponentProps> = ({
  className,
  tabs,
  setModalOpen,
  setActiveTab,
  activeTab
}) => {
  // const [activeTab, setActiveTab] = useState(0);
  const [phone, setPhone] = useState('')
  const [validity, setValidity] = useState({
    phone: true,
  })
  const [, setUserData] = useAtom(UserDataAtom)

  const handlePhoneChange = (value: string) => {
    setPhone(value)
    checkValidity('phone', value)
  }

  const handleSumbitClick = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault()
    if (!checkValidity('phone', phone)) return

      getUserData(convertToNumber(phone), GAME_ID).then((data) => {
        if (data) {
          setUserData(data)
          setModalOpen()
        }
      })
  }

  const checkValidity = (key: string, value: string, callback?:() => void) => {
    let valid = true;

    if (key === 'phone') {
      valid = value.length === 16
    }

    setValidity((prevState) => {
      return {
        ...prevState,
        [key]: valid
      }
    })

    return valid
  }


  return <>
    <CardWrapper>
      <div className={ classNames(className, styles.component) }>
        <div className={styles.buttons}>
          {tabs.map((tab, index) => (
            <button key={index} className={classNames(styles.button, index === activeTab && styles.button_active)} onClick={() => setActiveTab(index)}>
              <span>{tab.title}</span>
            </button>
          ))}
        </div>
        <ScrollContainer className={styles.scroll}>
          <table className={styles.table}>
            <thead className={styles.tableHeader}>
              <tr>
                <th>Place</th>
                <th>Phone Number</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody className={styles.tableBody}>
            {tabs[activeTab].content?.map((item, index) => (
              <tr key={index}>
                <td className={styles.place}>{item.place}</td>
                <td className={styles.phone}>
                  <span>{formatPhoneNumber(item.hiddenPhone)}</span>
                </td>
                <td className={styles.score}>{item.score}</td>
              </tr>
            ))}
            </tbody>
          </table>
        </ScrollContainer>
        <form action="" className={styles.form} onSubmit={handleSumbitClick}>
          <FormField 
              name='phone' 
              value={phone} 
              label='Enter your number'
              isLabelHidden={true} 
              placeholder='Enter your number' 
              isInvalid={!validity.phone}
              inputMode='tel' 
              mask='+{91} 0000-000-000'
              onAccept={handlePhoneChange}
            />
            <SubmitButton>
              <IconSearch></IconSearch>
            </SubmitButton>
        </form>
      </div>
    </CardWrapper>
  </>
};
