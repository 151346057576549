import { FC } from 'react';
import classNames from 'classnames';

import styles from './_Title.module.scss';

import type { ComponentProps } from './_Title.types';


export const Title: FC<ComponentProps> = ({
  children,
  size = 'm',
  tagName = 'h2',
  modifiers = [],
  className
}) => {
  const TagName = tagName
  const titleClass = classNames(
    styles.component, 
    styles[`component--${size}`],
    modifiers.map((modifier) => styles[`component--${ modifier }`]), 
    className)

  return <TagName className={titleClass}>{children}</TagName>
};
