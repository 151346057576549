export const formatPhoneNumber = (phoneNumber: string): string => {
    const countryCode = phoneNumber.substring(0, 2);
    const areaCode = phoneNumber.substring(2, 6);
    const mainDigits = phoneNumber.substring(6, 9);
    const lastDigits = phoneNumber.substring(9);

    return `+${countryCode} ${areaCode}-${mainDigits}-${lastDigits}`;
}

export const convertToNumber = (phoneNumber: string): number => { 
    const cleanedNumber = phoneNumber.replace(/\D/g, '');
    return parseInt(cleanedNumber, 10);
}
