export const IconRotate = () => (
<svg
    xmlns="http://www.w3.org/2000/svg"
    width={63}
    height={63}
    fill="none"
    viewBox="0 0 63 63"
  >
    <rect width={57} height={57} x={3.5} y={3.5} stroke="#1A1023" rx={5.5} />
    <g filter="url(#a)">
      <rect width={56} height={56} x={4} y={4} fill="#FF0041" rx={5} />
      <path
        fill="#fff"
        stroke="#B7002F"
        strokeLinecap="round"
        d="m46.75 30.022-1.357 1.285a14.244 14.244 0 0 0-16.242-14.413 14.246 14.246 0 1 0 6.047 27.765 1.39 1.39 0 0 0-.79-2.666 11.465 11.465 0 1 1 8.202-10.657l-1.35-1.674a1.39 1.39 0 1 0-2.165 1.746l4.093 5.074.34.422.393-.373 4.741-4.49a1.39 1.39 0 1 0-1.912-2.019Z"
      />
    </g>
    <defs>
      <filter
        id="a"
        width={56}
        height={58}
        x={4}
        y={4}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={2} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2004_5722"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_2004_5722"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={-4} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.601904 0 0 0 0 0 0 0 0 0 0.153427 0 0 0 1 0" />
        <feBlend in2="shape" result="effect2_innerShadow_2004_5722" />
      </filter>
    </defs>
  </svg>
);