import { FC } from 'react';
import classNames from 'classnames';

import styles from './_SubmitButton.module.scss';

import type { ComponentProps } from './_SubmitButton.types';


export const SubmitButton: FC<ComponentProps> = ({
  className,
  children, 
  onClick,
}) => {
  return <>
    <button type='submit' className={ classNames(className, styles.component) } onClick={onClick}>
      { children }
    </button>
  </>
};
