import {
  FC,
  memo,
  useEffect,
  useRef
} from 'react';
import { useAtom } from 'jotai';
import { Sprite } from '@pixi/react';

import type { ComponentProps } from './_Mud.types';

import {
  playerDataAtom,
  collidedEntityAtom,
  fieldCenterAtom
} from 'atoms';

import {
  checkCollision,
  getRandomInteger
} from 'helpers';
import { DEFAUL_DISTANCE_MULTIPLIER } from 'const';


const BASE_SIZE = 70;


export const Mud: FC<ComponentProps> = memo(({
  texture,
  position,
  stageWidth,
  stageHeight,
  setDestroyed
}) => {
  const size = BASE_SIZE * stageWidth / 320;

  const [ playerData, ] = useAtom(playerDataAtom);
  const [ , setCollidedEntity ] = useAtom(collidedEntityAtom);
  const [center,] = useAtom(fieldCenterAtom)

  const componentRef = useRef<any>();
  const textureNameRef = useRef<string>(`mud-${ getRandomInteger(0, 3) }`);


  useEffect(() => {
    if (!componentRef.current || !playerData.ref || playerData.isTurbo) {
      return;
    }

    const collison = checkCollision(componentRef.current, playerData.ref);

    if (collison) {
      setCollidedEntity('mud');
    }
  });

  useEffect(() => {
    const {x: playerX, y: playerY} = center
    const {x:  componentX, y: componentY} = position
    if (componentX===0 || componentY===0) return
    const xDistance = stageWidth * DEFAUL_DISTANCE_MULTIPLIER 
    const yDistance = stageHeight * DEFAUL_DISTANCE_MULTIPLIER 
    const deltaX = Math.abs(playerX - componentX)
    const deltaY = Math.abs(playerY - componentY)

    if (deltaX >= xDistance || deltaY >= yDistance) {
      setDestroyed(true)
    }
  }, [center, center.x, center.y, position, setDestroyed, stageHeight, stageWidth])


  return <>
    <Sprite
      ref={ componentRef }
      texture={ texture.textures[textureNameRef.current] }
      width={ size }
      height={ size }
      position={ [position.x - size/2, position.y - size/2] }  
      anchor={ 0.5 }
    />
  </>
});
