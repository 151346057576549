import { FC, useEffect, useState } from 'react';

import styles from './_ResultsCard.module.scss';

import type { ComponentProps } from './_ResultsCard.types';
import { CardWrapper, Title } from 'components';

import { useAtom } from 'jotai';
import { ScoreAtom } from 'atoms/_scoreAtom';
import { GAME_ID } from 'const';
import { getUserRank } from 'api/user';

const img = {
  src: './img/catched.png',
  srcset: './img/catched@2x.png',
  alt: ' '
}


export const ResultsCard: FC<ComponentProps> = ({
  className,
}) => {
  const [score,] = useAtom(ScoreAtom)
  const [rank, setRank] = useState(0)

  useEffect(() => {
    getUserRank(GAME_ID, score.score).then((data) => {
      setRank(data.place)
    })
  }, [score.score])
  
  return <>
    <CardWrapper>
      <div className={styles.component}>
        <Title className={styles.title} modifiers={['uppercase']}>Results</Title>

        <ul className={styles.results}>
          <li className={styles.result}>
            <Title className={styles.resultContent} tagName='h3' size='s'>
              Runs
              <span>{score.runs}</span>
            </Title>
          </li>
          <li className={styles.result}>
            <Title className={styles.resultContent} tagName='h3' size='s'>
              Score
              <span>{score.score}</span>
            </Title>
          </li>
          <li className={styles.result}>
            <Title className={styles.resultContent} tagName='h3' size='s'>
              Rank
              <span>{`#${rank && rank}`}</span>
            </Title>
          </li>
        </ul>

        <div className={styles.bottom}>
          <div className={styles.img}>
            <img src={img.src} srcSet={img.srcset} width={100} height={91} alt={img.alt}/>
          </div>
          <div className={styles.text}>
            <p>Dodge obstacles, avoid getting caught by catchers, and don't forget to pick up batteries to last as long as possible, reach the top, and win prizes!</p>
          </div>
        </div> 
      </div>
    </CardWrapper>
  </>
};
