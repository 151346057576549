import {
  FC,
  memo,
  useMemo,
  useEffect,
  useRef
} from 'react';
import {
  AnimatedSprite,
  Container
} from '@pixi/react';
import { useAtom } from 'jotai';

import type { ComponentProps } from './_Player.types';

import {
  entityTexturesAtom,
  playerDataAtom
} from 'atoms';


const BASE_SIZE = 54;


export const Player: FC<ComponentProps> = memo(({
  stageSize,
  movementDirection,
  state
}) => {
  const [ entityTextures ] = useAtom(entityTexturesAtom);
  const [ , setPlayerData ] = useAtom(playerDataAtom);

  const componentRef = useRef<any>();

  const texturePrimaryKey = !movementDirection || state === 'idle' || state === 'idle_damage' || state === 'idle_turbo' ?
    `ball`
    :
    `ball_${ movementDirection }`;

  const textureKey = `${ texturePrimaryKey }${ state ? `_${ state }` : '' }`;
  
  const texture = entityTextures[texturePrimaryKey]?.animations[textureKey];

  const size = BASE_SIZE * stageSize.width / 320;

  const position = useMemo(() => ({
    x: stageSize.width / 2,
    y: stageSize.height / 2
  }), [stageSize]);


  useEffect(() => {
    setPlayerData((current) => ({
      ...current,
      ...position,
      width: size,
      height: size,
      ref: componentRef.current
    }));
  }, [setPlayerData, position, size]);


  return <>
    <Container
      { ...position  }
      ref={ componentRef }
      width={ size }
      height={ size }
      anchor={ 0.5 }
      interactiveChildren={ false }
    >
      <AnimatedSprite
        key={ textureKey }
        textures={ texture }
        width={ size }
        height={ size }
        position={ [0, 0] }
        anchor={ 0.5 }
        isPlaying={ true }
      />
    </Container>
  </>
});
