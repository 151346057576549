import { FC } from 'react';
import { AnimatedSprite, Container } from '@pixi/react';

import type { ComponentProps } from './_CatcherSprite.types';


export const CatcherSprite: FC<ComponentProps> = ({
  textures,
  tilePosition,
  size,
  componentRef
}) => {
  if (!textures) return null
  return <>
    <Container  
      position={ [tilePosition.x - size/2, tilePosition.y - size/2] }  
      ref={componentRef}
      width={ size }
      height={ size }
      anchor={ 0.5 }  
    >
      <AnimatedSprite
        textures={ textures }
        width={ size }
        height={ size }
        position={[size/2,size/2]}
        anchor={ 0.5 } 
        animationSpeed={ 0.5 }
        isPlaying={ true }
      />
    </Container>
  </>
};
