import { FC } from 'react';
import classNames from 'classnames';

import styles from './_ScrollContainer.module.scss';

import 'simplebar-react/dist/simplebar.min.css';
import type { ComponentProps } from './_ScrollContainer.types';
import SimpleBar from 'simplebar-react';


export const ScrollContainer: FC<ComponentProps> = ({
  className,
  children
}) => {
  return <>
   <SimpleBar className={ classNames(className, styles.component) }>
      { children }
    </SimpleBar>
  </>
};
