import { FC, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';

import styles from './_Catched.module.scss';

import type { ComponentProps } from './_Catched.types';
import { Background, Title } from 'components';
import { SetRouteContext } from 'context';


export const Catched: FC<ComponentProps> = ({
  className,
}) => {
  const [isActive, setIsActive] = useState(false)
  const setRoute = useContext(SetRouteContext);

  useEffect(() => {
    setIsActive(true)

    setTimeout(() => {
      setRoute('result')
    }, 2000)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>
    <Background className={classNames(styles.component, isActive && styles.isActive)} color='overlay'>
      <div className={styles.wrapper}>
        <Title className={styles.title} modifiers={['shadow', 'uppercase']}>Catched</Title>
      </div>
    </Background>
  </>
};
