export const KeyCode = {
  ArrowUp: 'ArrowUp',
  ArrowRight: 'ArrowRight',
  ArrowDown: 'ArrowDown',
  ArrowLeft: 'ArrowLeft',

  A: 'KeyA',
  D: 'KeyD',
  S: 'KeyS',
  W: 'KeyW'
} as const;
