import { FC, useEffect, useRef } from 'react';
import classNames from 'classnames';

import styles from './_Pretimer.module.scss';

import type { ComponentProps } from './_Pretimer.types';
import Lottie from 'lottie-react';


export const Pretimer: FC<ComponentProps> = ({
  className,
  lottieData,
  isActive,
  setPretimerActive,
  setIsPlaying
}) => {
  const lottie = useRef<any>()

  useEffect(() => {
    if (!lottieData) return

    if (isActive) {
      lottie.current.play()
    } else {
      lottie.current.pause()
    }
  }, [isActive, lottieData])

  
  return <>
    <div className={ classNames(className, styles.component) }>
      {lottieData && (<Lottie 
        lottieRef={lottie} 
        rendererSettings={{preserveAspectRatio: 'xMidYMid slice'}} 
        animationData={lottieData} 
        autoplay={false}
        loop={false}
        onComplete={() => {
          setPretimerActive(false)
          setIsPlaying(true)
        }}
        />)}
    </div>
  </>
};
