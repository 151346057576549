import { FC } from 'react';
import classNames from 'classnames';

import styles from './_FormCheckbox.module.scss';

import type { ComponentProps } from './_FormCheckbox.types';


export const FormCheckbox: FC<ComponentProps> = ({
  className,
  id,
  checked,
  onChange,
  type="radio",
  children,
  isInvalid,
  name
}) => {

  return (
    <div className={classNames(styles.el, className)}>
      <input
        className={classNames(styles.input, "visually-hidden")}
        type={type}
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id} className={classNames(styles.label, isInvalid && styles.label_invalid)}>{children}</label>
    </div>
  )
}