import { FC } from 'react';
import classNames from 'classnames';

import styles from './_ControlHint.module.scss';

import type { ComponentProps } from './_ControlHint.types';
import { IconTouch, IconWASD } from 'icons';

const Content = {
  Touch: {
    icon: <IconTouch />,
    texts: [
      "Press on the screen and control the ball by tilting the stick on the appearing joystick.",
      "Dodge obstacles and don't get caught by catchers!"
    ]
  },
  WASD: {
    icon: <IconWASD />,
    texts: [
      "Control the ball using the WASD keys or arrow keys.",
      "Dodge obstacles and don't get caught by catchers!"
    ]
  },
}


export const ControlHint: FC<ComponentProps> = ({
  className,
  isTouch
}) => {
  const currentContent = isTouch ? Content.Touch : Content.WASD
  return <>
    <div className={ classNames(className, styles.component) }>
      <div className={styles.wrapper}>
        <div className={styles.icon}>
          {currentContent.icon}
        </div>
        <div className={styles.texts}>
          {currentContent.texts.map((text, index) => (
            <p className={styles.text} key={index}>{text}</p>
          ))}
        </div>
      </div>
    </div>
  </>
};
