import { FC, useContext } from 'react';
import classNames from 'classnames';
import { ModalWrapper, CardWrapper, Slider } from 'components';

import styles from './_Guide.module.scss';

import type { ComponentProps } from './_Guide.types';
import { SetRouteContext } from 'context';


export const Guide: FC<ComponentProps> = ({
  className,
}) => {
  const setRoute = useContext(SetRouteContext);
  return <>
      <ModalWrapper className={classNames(styles.component, className)} title={'how to play'} button={{
          children: 'back',
          onClick: ()=>setRoute('menu')
      }}>
        <CardWrapper>
          <Slider/>
        </CardWrapper>
      </ModalWrapper>
  </>
};
