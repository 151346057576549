import { FC, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';

import { GameCard, ModalWrapper } from 'components';
import styles from './_OtherGames.module.scss';

import type { ComponentProps } from './_OtherGames.types';

import { SetRouteContext } from 'context';
import { GAME_ID } from 'const';
import { getGames } from 'api/games';
import { GamesResDataI } from 'types';


export const OtherGames: FC<ComponentProps> = ({
  className
}) => {
  const setRoute = useContext(SetRouteContext);
  const [gamesData, setGamesData] = useState<GamesResDataI[]>([])

  useEffect(() => {
    
    getGames(GAME_ID).then((response) => {
      if (response) {
        setGamesData(response)
      }
    }) 
  }, [])

  return <>
      <ModalWrapper className={classNames(styles.component, className)} title={'batery games'} button={{
          children: 'back',
          onClick: ()=>setRoute('menu')
      }}>
        <div className={styles.wrapper}>
          {gamesData?.map((game, index) => (
            <GameCard game={game} key={game.id}/>
          ))}
        </div>
      </ModalWrapper>
  </>
};
