import {
  FC,
  useState,
  useEffect
} from 'react';
import { TilingSprite } from '@pixi/react';

import type { ComponentProps } from './_Field.types';
import { EntitiesContainer } from './EntitiesContainer';


const TILES_PER_SPRITE = 2;


const getTileImage = ( tileSize: number ) => {
  const spriteSize = tileSize * TILES_PER_SPRITE;

  const image = document.createElement('img');

  image.src = `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="none" width="${ spriteSize }" height="${ spriteSize }" viewBox="0 0 70 70"%3E%3Cg clip-path="url(%23a)"%3E%3Cg clip-path="url(%23b)"%3E%3Cpath fill="%234DDF4D" d="M0 0h320v423H0z"/%3E%3Cpath fill="%2358E858" d="M0 0h320v35H0z"/%3E%3Cpath fill="%234DDF4D" d="M0 35.25h320V70.5H0z"/%3E%3Cpath fill="%23fff" fill-opacity=".1" d="M.0000203 422.375 0 .00000141 35.5556 0v422.375H.0000203Z"/%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3CclipPath id="a"%3E%3Cpath fill="%23fff" d="M0 0h70v70H0z"/%3E%3C/clipPath%3E%3CclipPath id="b"%3E%3Cpath fill="%23fff" d="M0 0h320v423H0z"/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E`;

  return image;
};


export const Field: FC<ComponentProps> = ({
  width,
  height,
  tileSize,
  movementDistance,
  movementDirection,
  children
}) => {
  const [ tilePosition, setTilePosition ] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (!movementDistance) {
      return;
    }

    if (movementDirection === 'up') {
      setTilePosition((current) => ({
        x: current.x,
        y: current.y + movementDistance
      }));
    }

    if (movementDirection === 'down') {
      setTilePosition((current) => ({
        x: current.x,
        y: current.y - movementDistance
      }));
    }

    if (movementDirection === 'left') {
      setTilePosition((current) => ({
        x: current.x + movementDistance,
        y: current.y
      }));
    }

    if (movementDirection === 'right') {
      setTilePosition((current) => ({
        x: current.x - movementDistance,
        y: current.y
      }));
    }

    if (movementDirection === 'left-up') {
      setTilePosition((current) => ({
        x: current.x + movementDistance,
        y: current.y + movementDistance
      }));
    }

    if (movementDirection === 'left-down') {
      setTilePosition((current) => ({
        x: current.x + movementDistance,
        y: current.y - movementDistance
      }));
    }

    if (movementDirection === 'right-up') {
      setTilePosition((current) => ({
        x: current.x - movementDistance,
        y: current.y + movementDistance
      }));
    }

    if (movementDirection === 'right-down') {
      setTilePosition((current) => ({
        x: current.x - movementDistance,
        y: current.y - movementDistance
      }));
    }
  }, [movementDirection, movementDistance]);


  return <>
    <TilingSprite
      image={ getTileImage(tileSize) }
      width={ width }
      height={ height }
      tilePosition={ tilePosition }
      tileScale={{ x: 1, y: 1 }}
    />
    <EntitiesContainer tilePosition={tilePosition} width={width} height={height} >
      {children}
    </EntitiesContainer>
  </>
};
