import {
    API_URL,
    Endpoint
} from 'const';

import { makeApiCall } from 'api/_makeApiCall';
import { GamesResDataI } from 'types';

export const getGames = (gameId: number): Promise<GamesResDataI[]> => {
    return makeApiCall(`${API_URL}/${Endpoint.Games}?game_id=${gameId}`)
        .then((response) => {
            if (response.data) {
                return response.data
            }
        })
} 