import { FC } from 'react';
import classNames from 'classnames';

import styles from './_PersonalScore.module.scss';

import type { ComponentProps } from './_PersonalScore.types';
import { CardWrapper, ModalWrapper, ScrollContainer, Title } from 'components';
import { formatPhoneNumber } from 'helpers';


export const PersonalScore: FC<ComponentProps> = ({
  className,
  data,
  onClick,
}) => {
  if (!data) return <></>

  return <>
      <ModalWrapper className={classNames(styles.component, className)} title={'leaderboard'} button={{
          children: 'back',
          onClick: onClick,
      }} color='overlay'>
        <CardWrapper>
          <div className={styles.wrapper}>
            <div className={styles.titleWrapper}>
              <Title className={styles.title} size='s'>Find your score</Title>
            </div>
            <ScrollContainer className={styles.scroll}>
              <div className={styles.content}>
                <div className={styles.contentWrapper}>
                  <p className={styles.contentText}>Your phone number</p>
                  <div className={styles.phone}>
                    <span>{formatPhoneNumber(data.phone.toString())}</span>
                  </div>
                </div>
                <div className={styles.contentWrapper}>
                  <p className={styles.contentText}>Lucky Run</p>
                  <div className={styles.score}>
                    <div className={styles.scoreItem}>
                      <p className={styles.scoreTitle}>Place</p>
                      <div className={styles.scoreWrapper}>
                        <span>{`#${data.gameLeaderBoard.place}`}</span>
                      </div>
                    </div>
                    <div className={styles.scoreItem}>
                      <p className={styles.scoreTitle}>Best score</p>
                      <div className={styles.scoreWrapper}>
                        <span>{data.gameLeaderBoard.score}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.contentWrapper}>
                  <p className={styles.contentText}>Batery Total</p>
                  <div className={styles.score}>
                    <div className={styles.scoreItem}>
                      <p className={styles.scoreTitle}>Place</p>
                      <div className={styles.scoreWrapper}>
                        <span>{`#${data.batteryLeaderBoard.place}`}</span>
                      </div>
                    </div>
                    <div className={styles.scoreItem}>
                      <p className={styles.scoreTitle}>Best score</p>
                      <div className={styles.scoreWrapper}>
                        <span>{data.batteryLeaderBoard.score}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ScrollContainer>
          </div>
        </CardWrapper>
      </ModalWrapper>
  </>
};
