/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC,} from 'react';

import styles from './_SaveForm.module.scss';

import type { ComponentProps } from './_SaveForm.types';
import { CardWrapper, Title, FormField, SubmitButton, FormCheckbox } from 'components';
import { IconRight } from 'icons';


export const SaveForm: FC<ComponentProps> = ({
  className,
  phone, 
  validity,
  handlePhoneChange,
  checked,
  handleCheckbox,
}) => {
  return <>
    <CardWrapper>
      <div className={styles.component}>
        <Title className={styles.title} size='s'>To save your results,<br /> enter you phone number</Title>
        <div className={styles.form}>
          <div className={styles.input}>
            <FormField
              name='phone'
              value={phone}
              label='Enter your number'
              isLabelHidden={true}
              placeholder='Enter your number'
              isInvalid={!validity.phone}
              inputMode='tel'
              mask='+{91} 0000-000-000'
              onAccept={handlePhoneChange}
            />
            <SubmitButton>
              <IconRight></IconRight>
            </SubmitButton>
          </div>
          <FormCheckbox
            className={styles.checkbox}
            type='checkbox'
            id="agree"
            checked={checked}
            name='agree'
            onChange={handleCheckbox}
            isInvalid={!validity.agree}
          >
            <p>I agree with <a href="#">Batery.ai</a> Privacy Policy and T&C'</p>
          </FormCheckbox>
        </div>
        <div className={styles.bottom}>
          <p>Using it we will be able to combine the results with other games on the Batery platform and contact with you if you win. If you previously earned more points, the result will not be overwritten</p>
        </div>
      </div>
    </CardWrapper>
  </>
};
