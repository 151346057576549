import { FC, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';

import styles from './_Results.module.scss';

import type { ComponentProps } from './_Results.types';

import {ModalWrapper, ResultsCard } from 'components';
import { SetRouteContext } from 'context';
import { Confirm } from 'modals/Confirm';
import { LoaderScreen } from 'modals';
import { setUserData } from 'api/user';
import { GAME_ID } from 'const';
import { useAtom } from 'jotai';
import { ScoreAtom, defaultScore } from 'atoms/_scoreAtom';


export const Results: FC<ComponentProps> = ({
  className
}) => {
  const [score, setScore] = useAtom(ScoreAtom)
  const [isModalOpen, setModalOpen] = useState(false)
  const [queryParam, setQueryParam] = useState<string | null>(null);
  
  const [loading, setLoading] = useState(false);
	const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    
    urlParams.get('phone')
    setQueryParam(urlParams.get('phone'));
  }, []);

  const setRoute = useContext(SetRouteContext);

  const handleSave = () => {
    if (queryParam) {
      setLoading(true)

      setUserData({
        gameID: GAME_ID,
        isLocalGameReg: false,
        phone: Number(queryParam),
        score: score.score,
        username: ''
      }).then(() => {
        setLoaded(true)
  
        setTimeout(() => {
          setRoute('menu')
          setScore(defaultScore)
        }, 3000)
      })
    } else {
      setRoute('save')
    }
  }

  const TitleText = () => {
    return <><span className={styles.shrink}>Sa</span>ving</>
  }

  return <>
     <ModalWrapper className={classNames(styles.component, className)} title={'catched'} buttons={[
      {
        children: 'back to menu',
        modifiers: ['yellow'],
        onClick: () => setModalOpen(true)
      },
      {
        children: 'save results',
        onClick: ()=>{handleSave()}
      }
     ]}>
        <ResultsCard />
      </ModalWrapper>  
      
      {isModalOpen && <Confirm onClick={() => setModalOpen(false)} />}
      {loading ? (<LoaderScreen className={styles.modal} titleModifiers={['shadow-2', 'uppercase']} titleText={TitleText()} titleSize='xxl' loaded={loaded} loading={loading} setLoading={setLoading} animationDuration={3000} color='overlay' />) : null }
  </>
};
