import {
  FC,
  useState,
  useEffect
} from 'react';
import classNames from 'classnames';
import { Joystick as JoystickComponent } from 'react-joystick-component';

import styles from './_Joystick.module.scss';

import type { IJoystickUpdateEvent } from 'react-joystick-component/build/lib/Joystick';
import type { ComponentProps } from './_Joystick.types';


const BASE_SIZE = 120;
const MIN_EDGE_OFFSET = 10;


export const Joystick: FC<ComponentProps> = ({
  className,
  parentFrameRef,
  onMove
}) => {
  const [ position, setPosition ] = useState<{ top: number, left: number } | null>(null);

  const parentFrameWidth = parentFrameRef.current?.clientWidth || 0;
  const size = BASE_SIZE * parentFrameWidth / 320;


  const handleMove = ({ x, y, direction }: IJoystickUpdateEvent) => {
    if ( !x || !y) {
      return;
    }

    if (direction === 'LEFT') {
      if (y >= 0.4) {
        onMove('left-up');
        return;
      }

      if (y <= -0.2) {
        onMove('left-down');
        return;
      }

      onMove('left');
    }

    if (direction === 'RIGHT') {
      if (y >= 0.2) {
        onMove('right-up');
        return;
      }

      if (y <= -0.4) {
        onMove('right-down');
        return;
      }

      onMove('right');
    }

    if (direction === 'FORWARD') {
      if (x <= -0.4) {
        onMove('left-up');
        return;
      }

      if (x >= 0.4) {
        onMove('right-up');
        return;
      }

      onMove('up');
    }
    
    if (direction === 'BACKWARD') {
      if (x <= -0.4) {
        onMove('left-down');
        return;
      }
      
      if (x >= 0.4) {
        onMove('right-down');
        return;
      }

      onMove('down');
    }
  };


  useEffect(() => {
    const frame = parentFrameRef.current;

    if (!frame) {
      return;
    }

    const hadleTouchStart = (evt: TouchEvent) => {
      if ((evt.target as HTMLElement).closest(`.${ styles.component }`)) {
        return;
      }

      const touchData = evt.touches[0];
      const frameRect = frame.getBoundingClientRect();

      const newPosition = {
        top: touchData.clientY - frameRect.top - size / 2,
        left: touchData.clientX - frameRect.left - size / 2
      };

      if (newPosition.top <= 0) {
        newPosition.top = MIN_EDGE_OFFSET;
      }

      if (newPosition.left <= 0) {
        newPosition.left = MIN_EDGE_OFFSET;
      }

      if (frameRect.left + newPosition.left + size >= frameRect.right) {
        newPosition.left = frameRect.right - frameRect.left - size - MIN_EDGE_OFFSET;
      }

      if (frameRect.top + newPosition.top + size >= frameRect.bottom) {
        newPosition.top = frameRect.bottom - frameRect.top - size - MIN_EDGE_OFFSET;
      }

      setPosition(newPosition);
    };

    frame.addEventListener('touchstart', hadleTouchStart);

    return () => {
      frame.removeEventListener('touchstart', hadleTouchStart);
    }
  }, [size, parentFrameRef]);


  return position && <>
    <div
      className={ classNames(className, styles.component) }
      style={{ ...position }}
    >
      <JoystickComponent
        size={ size }
        stickSize={ size / 3 }
        move={ handleMove }
        stop={ () => onMove(null) }
      />
    </div>
  </>
};
