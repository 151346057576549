import { FC } from 'react';
import classNames from 'classnames';

import styles from './_CardWrapper.module.scss';

import type { ComponentProps } from './_CardWrapper.types';


export const CardWrapper: FC<ComponentProps> = ({
  className,
  children
}) => {
  return <>
    <div className={ classNames(className, styles.component) }>
      <div className={styles.wrapper}>
         { children }
      </div>
    </div>
  </>
};
