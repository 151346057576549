import {
    API_URL,
    Endpoint
} from 'const';

import type {
    LeaderboardResI,
} from 'types';
  

import { makeApiCall } from 'api/_makeApiCall';

export const getBatteryLeaderboard = (limit?: number): Promise<LeaderboardResI> => {
    return makeApiCall(`${API_URL}/${Endpoint.Leaderboard.Battery}${limit ? `?limit=${limit}` : ''}`)
        .then((response) => {
            if (response) {
                return response
            }
        })
}

export const getGameLeaderboard = (limit?: number): Promise<LeaderboardResI> => {
    return makeApiCall(`${API_URL}/${Endpoint.Leaderboard.Game}${limit ? `?limit=${limit}` : ''}`)
        .then((response) => {
            if (response) {
                return response
            }
        })
}